import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import MenuIcon from '@mui/icons-material/Menu';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SchoolIcon from '@mui/icons-material/School';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaHome, FaUser, FaProjectDiagram, FaTools, FaUserGraduate } from "react-icons/fa";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth, firestore } from '../firebaseConfig';
import {  doc, getDoc, } from "firebase/firestore";
import {  signOut } from "firebase/auth";

export default function Navbar() {
  const [anchorElMainMenu, setAnchorElMainMenu] = useState(null);
  const [anchorElLoginMenu, setAnchorElLoginMenu] = useState(null);
  const [user, setUser] = useState(null);
  const [displayName, setdisplayName] = useState("");
  

  const openMainMenu = Boolean(anchorElMainMenu);
  const openLoginMenu = Boolean(anchorElLoginMenu);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = doc(firestore, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser({ ...user, fullName: userSnap.data().fullName });
        } else {
          setUser(user);
        }
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

    const handleLogout = async () => {
      try {
        await signOut(auth);
        toast.success('Logged out successfully!');
      } catch (error) {
        toast.error(getCustomErrorMessage(error));
      }
    };

  const handleMainMenuClick = (event) => {
    setAnchorElMainMenu(event.currentTarget);
  };

  const handleLoginMenuClick = (event) => {
    setAnchorElLoginMenu(event.currentTarget);
  };

  const handleMainMenuClose = () => {
    setAnchorElMainMenu(null);
  };

  const handleLoginMenuClose = () => {
    setAnchorElLoginMenu(null);
  };

  // Theme handling
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || "system"
  );

  useEffect(() => {
    const applyTheme = (selectedTheme) => {
      if (selectedTheme === "system") {
        const systemPrefersDark = window.matchMedia(
          "(prefers-color-scheme: dark)"
        ).matches;
        document.body.setAttribute(
          "data-theme",
          systemPrefersDark ? "dark" : "light"
        );
      } else {
        document.body.setAttribute("data-theme", selectedTheme);
      }
      localStorage.setItem("theme", selectedTheme);
    };
    applyTheme(theme);

    // Listen for system theme changes
    const systemThemeListener = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    const handleSystemThemeChange = () => {
      if (theme === "system") {
        applyTheme("system");
      }
    };
    systemThemeListener.addEventListener("change", handleSystemThemeChange);

    return () => {
      systemThemeListener.removeEventListener(
        "change",
        handleSystemThemeChange
      );
    };
  }, [theme]);

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else if (theme === "dark") {
      setTheme("light");
    } else {
      setTheme("light");
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
 
      <AppBar position="static">

        <Toolbar className="bg-blue-800 py-4 shadow-xl">
          {/* Main Menu */}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            id="main-menu-button"
            aria-controls={openMainMenu ? "main-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMainMenu ? "true" : undefined}
            onClick={handleMainMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="main-menu"
            aria-labelledby="main-menu-button"
            anchorEl={anchorElMainMenu}
            open={openMainMenu}
            onClose={handleMainMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              style: {
                width: '250px',
                borderRadius: '10px',
                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <MenuItem onClick={handleMainMenuClose} className="flex items-center gap-2">
            
              <Link className="flex gap-2 items-center w-full cursor-pointer" style={{ textDecoration: "none", color: "inherit" }} to="/">
              <FaHome className="text-blue-500" /> Home
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMainMenuClose} className="flex items-center gap-2">
              
              <Link className="flex gap-2 items-center w-full cursor-pointer"
                style={{ textDecoration: "none", color: "inherit" }}
                to="/about"
              >
                <FaUser className="text-green-500" />
                About Me
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMainMenuClose} className="flex items-center gap-2">
              
              <Link className="flex gap-2 items-center w-full cursor-pointer"
                style={{ textDecoration: "none", color: "inherit" }}
                to="/courses"
              >
                <FaUserGraduate className="text-pink-500" />
                Courses
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMainMenuClose} className="flex items-center gap-2">
              
              <Link className="flex gap-2 items-center w-full cursor-pointer"
                style={{ textDecoration: "none", color: "inherit" }}
                to="/projects"
              >
                <FaProjectDiagram className="text-purple-500" />
                Projects
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMainMenuClose} className="flex items-center gap-2">
              
              <Link className="flex gap-2 items-center w-full cursor-pointer"
                style={{ textDecoration: "none", color: "inherit" }}
                to="/services"
              >
                <FaTools className="text-orange-500" />
                Services
              </Link>
            </MenuItem>
          </Menu>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link style={{ textDecoration: "none", color: "inherit" }} to="/">
              OFFICIALKASUN
            </Link>
            <small> v3.0</small>
          </Typography>

        
                <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                id="login-menu-button"
                aria-controls={openLoginMenu ? "login-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openLoginMenu ? "true" : undefined}
                onClick={handleLoginMenuClick}
                >
                <span className="text-base font-bold hidden md:block">{user ? user.fullName ? user.fullName.substring(0, 5) + "..." : user.displayName ? user.displayName.substring(0, 5) + "..." : user.email.substring(0, 5) + "..." : "Login"}</span>{" "}
                <MoreVertIcon />
                </IconButton>
                <Menu
                id="login-menu"
                aria-labelledby="login-menu-button"
                anchorEl={anchorElLoginMenu}
                open={openLoginMenu}
                onClose={handleLoginMenuClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                >
                {!user ? (

                <div className="flex flex-col gap-3 p-2">
                 <MenuItem onClick={handleLoginMenuClose}>
                  <a
                  className="flex flex-row items-center justify-start w-full gap-5"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  href="https://client.officialkasun.com" target="_blank" rel="noreferrer"
                  >
                  <ManageAccountsIcon className="text-blue-500" /> Client Login
                  </a>
                </MenuItem>
                <MenuItem onClick={handleLoginMenuClose}>
                  <Link className="flex flex-row items-center justify-start w-full gap-5"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  to="/login"
                  >
                  <SchoolIcon className="text-pink-500" /> Student Login
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleLoginMenuClose}>
                  <a className="flex flex-row items-center justify-start w-full gap-5"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  href="https://learn.prolearners.lk" target="_blank" rel="noreferrer"
                  >
                  <CastForEducationIcon className="text-red-500" /> LMS Login
                  </a>
                </MenuItem>
               
                </div>
                ) : (

                <div>

              <MenuItem onClick={handleLoginMenuClose}>
                  <Link to={"/dashboard"}
                  className="flex flex-row items-center justify-start w-full gap-5 text-blue-500"
                  
                  >
                  <DashboardIcon /> Dashboard
                  </Link>
                </MenuItem>
                  
                 <MenuItem onClick={handleLoginMenuClose}>
                  <button
                  className="flex flex-row items-center justify-start w-full gap-5  text-red-500 font-semibold "
                  onClick={handleLogout}
                  >
                  <LogoutIcon /> Logout
                  </button>
                </MenuItem>
                </div>
                )}
                 

               
                </Menu>

              

                {/* Theme Toggle Button */}
          <div className="fixed flex md:relative shadow-lg md:shadow-inherit hover:bg-base-100 md:hover:bg-inherit bottom-4 md:bottom-0 bg-base-300 text-base-content md:text-inherit md:bg-inherit justify-center items-center text-center p-1 m-0 rounded-full h-10 w-10">
          <IconButton
            size="large"
            color="inherit"
            aria-label="toggle theme"
            onClick={toggleTheme}
          >
            {theme === "light" && <LightModeIcon />}
            {theme === "dark" && <DarkModeIcon />}
            {theme === "system" && <SettingsBrightnessIcon />}
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}