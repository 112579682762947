import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { FaExclamationTriangle, FaSearch } from 'react-icons/fa';

const AllCourses = ({ mode, count }) => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [fixedSearch, setFixedSearch] = useState('');

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async (term = '') => {
    setLoading(true);
    setFixedSearch(term);
    try {
      let q = query(collection(firestore, 'courses'), where('enabled', '==', true));
      if (term) {
        q = query(
          collection(firestore, 'courses'),
          where('enabled', '==', true),
          where('keywords', 'array-contains', term.toLowerCase())
        );
      }
      const querySnapshot = await getDocs(q);
      const coursesList = querySnapshot.docs.map(doc => doc.data());
      setCourses(coursesList);
     
    } catch (error) {
      console.error("Error fetching courses:", error);
    } finally {
      setLoading(false);
    }
  };

  const searchCourse = () => {
    fetchCourses(searchTerm);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchCourse();
    }
  };

  const displayedCourses = count ? courses.slice(0, count) : courses;

  const searchBar = () => (
    <div className="w-full mb-8">
      <div className="max-w-md mx-auto relative">
        <input
          type="text"
          placeholder="Browse Courses..."
          className="w-full px-4 py-3 pl-12 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary input input-bordered"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
          <FaSearch className="w-5 h-5 text-gray-400" />
        </div>
        <button
          onClick={searchCourse}
          className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
        >
          <FaSearch className="w-5 h-5 text-gray-400" />
        </button>
      </div>
    </div>
  );

  if (loading) {
    return (
      <>
          {mode !== 'nosearch' && searchBar()}
      <div className=" flex items-center justify-center">
        <span className="loading loading-spinner loading-lg text-red-500"></span>
      </div>
      </>
    );
  }

  if (!loading && courses.length === 0) {
    return (
      <>
        {mode !== 'nosearch' && searchBar()}
        <div className=" flex flex-col items-center justify-center gap-4 p-4">
          <FaExclamationTriangle className="text-6xl text-error animate-pulse" />
          <h2 className="text-3xl font-bold text-center">No Courses Found</h2>
          <p className="text-center text-lg text-gray-600">
            It seems there are no available courses { fixedSearch && "under the \"" +fixedSearch.toString() + "\""} at the moment.  </p>
        </div>
      </>
    );
  }

  return (
    <div className="w-full flex flex-wrap gap-8 p-4 justify-center">
      {mode !== 'nosearch' && searchBar()}
      {displayedCourses.map((course, index) => (
        <div 
          key={index}
          className="card bg-base-100 rounded-lg shadow-lg overflow-hidden p-6 transition-all duration-300 hover:scale-105 w-full sm:w-[calc(50%-1rem)] lg:w-[calc(33.333%-1rem)]"
        >
          <div className='flex flex-col gap-3'>
            <div className="flex items-center justify-center">
              {course.image ? (
                <img
                  src={course.image}
                  alt={course.title}
                  className="w-32 p-2 object-cover border-4 border-primary rounded-md"
                />
              ) : (
                <div className="skeleton w-32 h-32 rounded-full bg-base-300"></div>
              )}
            </div>
            <div className="text-center">
              <h2 className="text-xl font-semibold text-base-content">{course.title}</h2>
              <p className="text-gray-500 mt-2 line-clamp-3">{course.description}</p>
            </div>
            <div className="text-center">
              <h2 className="text-xl text-yellow-600 font-semibold">Course Fees</h2>
              <p className="text-red-600 font-semibold mt-2 line-clamp-3">{course.fees} {course.currency} /=</p>
            </div>
            <div className="flex justify-center">
              <Link 
                to={course.link} 
                className="btn btn-primary mt-4 px-8 hover:bg-primary-focus transition-colors"
              >
                View Course
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllCourses;