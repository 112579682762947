import React from 'react';

const Refund = () => {
  return (
    <div className=" text-gray-800 p-6 sm:p-10 lg:p-20">
      <div className="max-w-4xl mx-auto bg-base-100 text-base-content shadow-lg rounded-xl overflow-hidden hover:scale-105 transition-all duration-700">
        <div className="p-6 sm:p-10 text-justify">
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-blue-600 mb-6">
            Refund Policy
          </h1>
          <p className="mb-8 text-red-500">
            <strong>Last Updated: February 04, 2025</strong>
          </p>
          <p className=" text-base sm:text-lg mb-4">
          Thank you for exploring our premium services at <span className="font-bold">officialkasun.com</span>.

We are dedicated to enhancing your experience by offering convenient online services, saving you valuable time and eliminating the need for unnecessary visits to banks or our offices. 

<br /> <br />Your satisfaction is our top priority, and we are always here to assist you. If, for any reason, you are not fully satisfied with our services, please don’t hesitate to reach out—we’re here to help!
          </p>

          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Returns & Unenrollment</h2>
          <p className=" mb-4"> We accept returns within <span className="font-bold">24 hours</span> from the time of purchase or enrollment. To be eligible for a return, the purchased service must remain unused, or the project must be canceled on our end. Additionally, a separate return policy will be outlined in the agreement for development projects. Please review the agreement carefully before proceeding.

           <br /><br />
           If you have enrolled in a course but have not accessed any course content, you must notify our financial team within <span className="font-bold">24 hours</span> to request unenrollment and initiate the refund process.
          </p>

          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Refunds</h2>
          <p className=" mb-4">
          Once the unenrollment or cancellation has been processed on our end, we will notify you of the refund status and any further steps via email. For refunds related to bank transactions, we will provide you with a form to fill out your bank details to facilitate the refund process. Refunds will be processed to the original payment method used for the transaction. <span className='text-red-600 font-semibold'>( The refund will be given to the payment-initiated media itself. )</span> <br /> <br />

            Please refer to the following guidelines to understand the procedure in detail.
            <ul className="list-disc list-inside mb-4 flex flex-col gap-10 mt-10 ml-10">
            <li>
            <strong>Bank Transactions:</strong> If the payment was made via bank transfer, the full amount will be refunded to your personal bank account after deducting the transaction fee (approximately LKR 30.00 to LKR 50.00).
            </li>

          <li>
          <strong>Credit/Debit Card Payments via IPG:</strong> For payments made through the Internet Payment Gateway (IPG), a full refund can be processed within two business days. However, if the payment has already been transferred to our bank and is no longer accessible through the IPG service provider, the refund will be processed as a standard bank transaction. In such cases, the transaction fee (approximately LKR 30.00 to LKR 50.00) and a convenience fee (3.3% of the total payment) will be deducted before issuing the refund.
          </li>
      </ul>
          </p>

        <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Course Exchanges / Transfers</h2>  
        <p className="mb-4">  
        If you prefer to exchange your enrolled course for another course instead of requesting a refund, we offer flexible options to accommodate your needs. To initiate the exchange process, you must do so within <span className="font-bold">24 hours</span>, and the originally enrolled course must remain unopened and unaccessed.  

        The available exchange options are outlined below.  

        <ul className="list-disc list-inside mb-4 flex flex-col mt-10 ml-10 gap-2"> 
          <li>Exchange for another course of the same fee.</li> 
          <li>Exchange for the same course but in a different available batch.</li> 
          <li>Exchange for a course with a lower fee and receive a refund for the difference, as per the refund policy.</li>
          <li>Exchange for a course with a higher fee by paying the additional amount required.</li> 
        </ul>
        </p>  

        <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Non-Refundable Services and Projects</h2> 
        <ul className="list-disc list-inside mb-4"> 
          <li>Personalized or custom-made projects (e.g., web applications, bespoke software, landing pages, etc.).</li> 
          <li>Services or projects that are already in use or currently ongoing.</li> 
          <li>Courses that have been accessed or opened.</li> 
          <li>Funds or balances stored in our system (these can be used for future payments).</li> 
          <li>Course enrollments after the <span className="font-bold">24-hour</span> eligibility period.</li> 
        </ul>

          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Processing Time</h2>
          <p className="mb-4">
            Refunds and exchanges will be processed within <span className="font-bold">3 to 7 business days</span> upon receipt of your request, provided it is submitted within <span className="font-bold">24 hours</span> of the original transaction. Please be advised that the time required for the refund to reflect in your account may vary, depending on the processing times of your payment provider.
          </p>
          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Contact Us</h2>
          <p className="mb-4"> Should you have any questions or concerns regarding our refund policy, please do not hesitate to reach out to our dedicated customer support team. You can contact us via phone at <a className='font-semibold' href='tel:0094764783434'>0764783434</a> or by email at <a href='mailto:officialkasun@officialkasun.com' className='font-semibold'>officialkasun@officialkasun.com</a>. </p>
        </div>
      </div>
    </div>
  );
};

export default Refund;
