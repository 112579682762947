import React from 'react';

const Privacy = () => {
  return (
    <div className="text-gray-800 p-6 sm:p-10 lg:p-20">
      <div className="max-w-4xl mx-auto bg-base-100 text-base-content shadow-lg rounded-xl overflow-hidden hover:scale-105 transition-all duration-700">
        <div className="p-6 sm:p-10 text-justify">
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-blue-600 mb-6">
            Privacy Policy
          </h1>
          <p className="mb-8 text-red-500">
  <strong>Last Updated: January 30, 2025</strong>
</p>
          <p className="text-base sm:text-lg mb-4">
  <span className="font-bold">officialkasun.com</span> is dedicated to safeguarding the privacy and security of our customers' personal information. This Privacy Policy details how we collect, use, and protect your data when you visit or make a purchase on our website. By using our website, you acknowledge and agree to the terms outlined in this policy.
</p>


          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Information We Collect</h2>
          <p className="mb-4">
  When you visit our website, we may collect certain information, including:
</p>
<ul className="list-disc list-inside mb-4 flex flex-col gap-2 ml-10">
  <li>
    <strong>Personal Identification Information:</strong> This includes details such as your name, email address, and phone number, which you voluntarily provide during registration or checkout.
  </li>
  <li>
    <strong>Payment and Billing Information:</strong> To process transactions, we collect necessary billing details, including credit card information. However, we do not store your card details on our servers. These are securely managed by our trusted third-party payment processors, the partner Internet Payment Gateway, and the partner bank. We may retain partial card details in a masked format for reference purposes.
  </li>
  <li>
    <strong>Browsing Information:</strong> We automatically collect data such as your IP address, browser type, and device information using cookies and similar tracking technologies to enhance your experience and improve our services.
  </li>
</ul>


          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Use of Information</h2>
          <p className="mb-4">
  The information we collect may be used for the following purposes:
</p>
<ul className="list-disc list-inside mb-4 flex flex-col gap-2 ml-10">
  <li>
    <strong>Enrollment Processing:</strong> To process and fulfill your enrollments, including granting LMS access and subscribing you to our referral program.
  </li>
  <li>
    <strong>Customer Communication:</strong> To contact you regarding your purchases, provide customer support, and respond to inquiries or requests.
  </li>
  <li>
    <strong>Personalized Experience:</strong> To tailor your learning experience by offering relevant course recommendations and promotions.
  </li>
  <li>
    <strong>Service Improvement:</strong> To enhance our website, products, and services based on user feedback and browsing behavior.
  </li>
  <li>
    <strong>Security and Fraud Prevention:</strong> To detect and prevent fraud, unauthorized activities, and abuse of our platform.
  </li>
</ul>

          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Information Sharing</h2>
          <p className="mb-4">
  We value your privacy and do not sell, trade, or share your personal information with third parties without your consent, except in the following circumstances:
</p>
<ul className="list-disc list-inside mb-4 flex flex-col gap-2 ml-10">
  <li>
    <strong>Trusted Service Providers:</strong> We may share your information with third-party service providers who assist in website operations, payment processing, and Learning Management System (LMS) management (which is owned and operated by us). These providers are contractually required to handle your data securely and confidentially.
  </li>
  <li>
    <strong>Legal Compliance:</strong> We may disclose your information when required by law or in response to valid legal requests or court orders.
  </li>
</ul>

          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Data Security</h2>
          <p className="mb-4">
  We implement industry-standard security measures to safeguard your personal information from unauthorized access, alteration, disclosure, or destruction. Our security framework includes advanced technologies and best practices to enhance data protection.  
</p>
<p className="mb-4">
  To ensure the highest level of security, we utilize:
</p>
<ul className="list-disc list-inside mb-4 flex flex-col gap-2 ml-10">
  <li>
    <strong>Data Encryption:</strong> Sensitive information is encrypted both in transit and at rest using industry-standard encryption protocols such as AES (Advanced Encryption Standard) and TLS (Transport Layer Security).
  </li>
  <li>
    <strong>Secure Transmission:</strong> We enforce HTTPS (Hypertext Transfer Protocol Secure) across our website to ensure secure communication and prevent data interception.
  </li>
  <li>
    <strong>Hashing & Salting:</strong> Passwords and other critical data are securely hashed and salted using robust cryptographic algorithms like bcrypt or SHA-256 to prevent unauthorized access.
  </li>
  <li>
    <strong>Access Controls & Authentication:</strong> We enforce strict role-based access controls (RBAC) and multi-factor authentication (MFA) to restrict access to sensitive data.
  </li>
  <li>
    <strong>Regular Security Audits:</strong> Our systems undergo regular security audits, vulnerability assessments, and penetration testing to identify and address potential threats.
  </li>
  <li>
    <strong>Firewalls & Intrusion Detection:</strong> We utilize Web Application Firewalls (WAF) and Intrusion Detection/Prevention Systems (IDS/IPS) to monitor and block suspicious activities.
  </li>
  <li>
    <strong>Data Redundancy & Backups:</strong> We implement secure, automated data backups and redundancy strategies to ensure data integrity and recovery in case of system failures or cyber threats.
  </li>
</ul>
<p className="mb-4">
  While we take every possible precaution to protect your data, it is important to note that no method of transmission over the internet or electronic storage is entirely foolproof. However, by continuously enhancing our security protocols, we strive to minimize risks and maintain the integrity of your personal information.
</p>


<h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">
  Cookies and Tracking Technologies
</h2>
<p className="mb-4">
  We use cookies and similar tracking technologies to enhance your browsing experience, analyze website traffic, and understand your preferences and interactions with our website. These technologies help us improve functionality, personalize content, and provide a seamless user experience.
</p>
<p className="mb-4">
  You can manage or disable cookies through your browser settings; however, doing so may limit certain features and functionalities of our website. Please note that we do not store sensitive or confidential information in cookies.
</p>


          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Changes to the Privacy Policy</h2>
          <p className="mb-4">
  We reserve the right to update or modify this Privacy Policy at any time. Any changes will be reflected on this page with a revised <strong>“Last Updated”</strong> date. We encourage you to review this policy periodically to stay informed about how we collect, use, and protect your information.  
</p>



          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Contact Us</h2>
          <p className="mb-4"> Should you have any questions or concerns regarding our refund policy, please do not hesitate to reach out to our dedicated customer support team. We are committed to providing you with assistance and ensuring a seamless and enjoyable online experience. You can contact us via phone at <a className='font-semibold' href='tel:0094764783434'>0764783434</a> or by email at <a href='mailto:officialkasun@officialkasun.com' className='font-semibold'>officialkasun@officialkasun.com</a>. We are here to help! </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;

