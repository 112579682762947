import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig';
import { FaExclamationTriangle, FaSearch, FaCcVisa, FaCcMastercard, FaCcAmex, FaMobileAlt, FaQrcode } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

const PayTrack = () => {
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [payment, setPayment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const STATUS_MAPPING = {
    '2': { status: 'Success', color: 'bg-green-100 text-green-800', border: 'border-green-300' },
    '0': { status: 'Pending', color: 'bg-yellow-100 text-yellow-800', border: 'border-yellow-300' },
    '-1': { status: 'Canceled', color: 'bg-gray-100 text-gray-800', border: 'border-gray-300' },
    '-2': { status: 'Failed', color: 'bg-red-100 text-red-800', border: 'border-red-300' },
    '-3': { status: 'Chargedback', color: 'bg-purple-100 text-purple-800', border: 'border-purple-300' }
  };

  const PAYMENT_METHOD_MAPPING = {
    'VISA': { icon: FaCcVisa, type: 'Card Payment' },
    'MASTER': { icon: FaCcMastercard, type: 'Card Payment' },
    'AMEX': { icon: FaCcAmex, type: 'Card Payment' },
    'EZCASH': { icon: FaMobileAlt, type: 'Mobile Payment' },
    'MCASH': { icon: FaMobileAlt, type: 'Mobile Payment' },
    'GENIE': { icon: FaQrcode, type: 'QR / APP Payment' },
    'VISHWA': { icon: FaQrcode, type: 'QR / APP Payment' },
    'PAYAPP': { icon: FaQrcode, type: 'QR / APP  Payment' },
    'HNB': { icon: FaQrcode, type: 'QR / APP  Payment' },
    'FRIMI': { icon: FaQrcode, type: 'QR / APP  Payment' }
  };

  useEffect(() => {
    const orderId = searchParams.get('order_id');
    if (orderId) {
      setSearchTerm(orderId);
      fetchPaymentData(orderId);
    }
  }, [searchParams]);

  const updatePaymentRequestStatus = async (orderId, statusCode) => {
    try {
      const paymentRequestsRef = collection(firestore, 'payment_requests');
      const q = query(paymentRequestsRef, where('order_id', '==', orderId));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const paymentRequest = querySnapshot.docs[0];
        const status = {
          '2': 'success',
          '0': 'pending',
          '-1': 'canceled',
          '-2': 'failed',
          '-3': 'chargedback'
        }[statusCode] || 'unknown';

        await updateDoc(doc(firestore, 'payment_requests', paymentRequest.id), { status });
      }
    } catch (error) {
      toast.error('Error updating payment request. Please login first.');
      console.error('Error updating payment request:', error);
    }
  };

  const fetchPaymentData = async (orderId) => {
    setLoading(true);
    setError(null);
    try {
      const paymentsRef = collection(firestore, 'payments');
      const q = query(paymentsRef, where('order_id', '==', orderId));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const paymentData = querySnapshot.docs[0].data();
        setPayment(paymentData);
        await updatePaymentRequestStatus(orderId, paymentData.status_code);
      } else {
        setPayment(null);
        setError('No payment found with this Order ID');
      }
    } catch (error) {
      console.error('Error fetching payment:', error);
      setError('Error fetching payment details');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm) {
      fetchPaymentData(searchTerm);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <form onSubmit={handleSearch} className="max-w-md mx-auto mb-8">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Enter Order ID..."
            className="w-full px-4 py-3 pl-12 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
          />
          <div className="absolute inset-y-0 left-3 flex items-center">
            <FaSearch className="w-5 h-5 text-gray-400" />
          </div>
          <button
            type="submit"
            className="absolute inset-y-0 right-3 px-4 flex items-center bg-blue-600 text-white rounded-lg h-10 mt-1 font-semibold hover:bg-blue-900"
          >
            Track
          </button>
        </div>
      </form>

      {loading && (
        <div className="flex justify-center">
          <span className="loading loading-spinner loading-lg text-primary"></span>
        </div>
      )}

      {error && (
        <div className="flex items-center justify-center gap-2 p-4 bg-red-100 text-red-700 rounded-lg">
          <FaExclamationTriangle />
          <p>{error}</p>
        </div>
      )}

      {payment && (
        <div className="bg-base-100 rounded-lg shadow-lg p-6 max-w-2xl mx-auto">
          <div className={`mb-6 p-4 rounded-lg ${STATUS_MAPPING[payment.status_code].color} ${STATUS_MAPPING[payment.status_code].border} border`}>
            <h2 className="text-xl font-bold mb-2">Payment Status: {STATUS_MAPPING[payment.status_code].status}</h2>
            <p>{payment.status_message}</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 ">
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold text-orange-700">Order Details</h3>
                <p className="text-lg">{payment.order_id}</p>
              </div>
              <div>
                <h3 className="font-semibold text-orange-700">Payment Method</h3>
                <div className="flex items-center gap-2 mt-1">
                  {PAYMENT_METHOD_MAPPING[payment.method] && (
                    <>
                      {React.createElement(PAYMENT_METHOD_MAPPING[payment.method].icon, {
                        className: "w-8 h-8 text-primary"
                      })}
                      <div>
                        <p className="text-lg">{payment.method}</p>
                        <p className="text-sm text-gray-600">{PAYMENT_METHOD_MAPPING[payment.method].type}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div>
                <h3 className="font-semibold text-orange-700">Amount</h3>
                <p className="text-lg">{payment.payhere_currency} {payment.payhere_amount}</p>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <h3 className="font-semibold text-orange-700">Card Information</h3>
                <p>Card Number: {payment.card_no}</p>
                <p>Card Holder: {payment.card_holder_name}</p>
                <p>Expiry: {payment.card_expiry}</p>
              </div>
              <div>
                <h3 className="font-semibold text-orange-700">Transaction Time</h3>
                <p>{moment(payment.timestamp.toDate()).format('MMMM D, YYYY h:mm A')}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PayTrack;