import React from 'react'
import AllCourses from '../../components/allCourses'



const All = () => {
  return (
    <div>
      <div className='flex w-full flex-wrap justify-center gap-10 last:hover:scale-95 transition-all duration-700'>
       <AllCourses />
      </div>
    </div>
  )
}

export default All