import React from "react";
import { Box, Container, Typography, IconButton } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { styled } from "@mui/system";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaGithub, FaYoutube } from "react-icons/fa";
import { MdOutlinePayment } from "react-icons/md";
import { Link } from "react-router-dom";

const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: "#1a237e",
  color: "#ffffff",
  padding: theme.spacing(6, 0),
  marginTop: "auto"
}));

const FooterLink = styled(Typography)({
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  }
});

const SocialIconButton = styled(IconButton)({
  color: "#ffffff",
  margin: "0 8px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)"
  }
});

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const handleLinkClick = (section) => {

    switch (section){

      case "youtube":
            window.open("https://www.youtube.com/officialkasun", "_blank");
            break;
      
        case "facebook":
            window.open("https://www.facebook.com/officialkasun", "_blank");
            break;
      
          case "instagram":
            window.open("https://www.instagram.com/officialkasun", "_blank");
            break;
      
          case "twitter":
            window.open("https://twitter.com/officialkasun", "_blank");
            break;
      
          case "github":
            window.open("https://github.com/officialkasun", "_blank");
            break;
      
          case "linkedin":
            window.open("https://www.linkedin.com/in/kasunofficial", "_blank");
            break;
      

        default:
            break;
    }
    
   
  };

  return (
    <StyledFooter component="footer">
      <Container className="min-w-full justify-center items-center flex flex-wrap">
        <Grid className="w-full justify-between" container spacing={8}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom fontWeight="bold">
              OFFICIALKASUN.COM
            </Typography>
            <Typography variant="body2" color="inherit">
              © {currentYear} Kasun Madhusanka Jayasinghe
            </Typography>

            <Grid item xs={12} sm={6} mt={2} md={3}>
            <Typography variant="h6" gutterBottom>
             <div className="flex flex-row items-center gap-3"> <MdOutlinePayment />Payment Methods</div>
            </Typography>
            <Box>

            <div className="group relative w-48  ">
              <img  style={{
    transition: "all 0.7s",
  }} className="w-48  hover:scale-150 hover:ml-12 hover:mt-5 "  alt="VISA / MasterCard / QR Payments" src="https://raw.githubusercontent.com/officialkasun/prolearnerslk/refs/heads/main/assets/payment-methods-v1.png"/>
           </div>
             
            </Box>
          </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <FooterLink variant="body2" onClick={() => handleLinkClick("about")}>
            <Link to={'/about'} style={{ textDecoration: 'none', color: 'inherit' }}>About Me</Link>
            </FooterLink>
            <Box mt={1}>
              <FooterLink variant="body2" onClick={() => handleLinkClick("privacy")}>
              <Link to={'/tc/refund'} style={{ textDecoration: 'none', color: 'inherit' }}>Refund Policy</Link>
              </FooterLink>
            </Box>
            <Box mt={1}>
              <FooterLink variant="body2" onClick={() => handleLinkClick("privacy")}>
              <Link to={'/tc/privacy'} style={{ textDecoration: 'none', color: 'inherit' }}>Privacy Policy</Link>
              </FooterLink>
            </Box>
            <Box mt={1}>
              <FooterLink variant="body2" onClick={() => handleLinkClick("terms")}>
              <Link to={'/tc/terms'} style={{ textDecoration: 'none', color: 'inherit' }}>Terms & Conditions</Link>
              </FooterLink>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3} className="flex flex-col gap-2">
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2">
            <Link to={'mailto:officialkasun@officialkasun.com'} style={{ textDecoration: 'none', color: 'inherit' }}> Email: officialkasun@officialkasun.com</Link>
             
            </Typography>
            <Typography variant="body2">
             <Link to={'tel:0094764783434'} style={{ textDecoration: 'none', color: 'inherit' }}>Phone: +9476 478 3434</Link>
            </Typography>
            <Typography variant="body2">
            <a href={'https://maps.app.goo.gl/BB5HShirdWueocuf8'} target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>Address: No.173/A, 5th Lane, Bodhirajapura, Attawilluwa, Puttalam (61328)</a>
              
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Follow Me on
            </Typography>
            <Box>
            <SocialIconButton aria-label="youtube" onClick={() => handleLinkClick("youtube")}>
                <FaYoutube size={24} />
              </SocialIconButton>
              <SocialIconButton aria-label="facebook" onClick={() => handleLinkClick("facebook")}>
                <FaFacebook size={24} />
              </SocialIconButton>
              <SocialIconButton aria-label="twitter" onClick={() => handleLinkClick("twitter")}>
                <FaTwitter size={24} />
              </SocialIconButton>
              <SocialIconButton aria-label="instagram" onClick={() => handleLinkClick("instagram")}>
                <FaInstagram size={24} />
              </SocialIconButton>
              <SocialIconButton aria-label="linkedin" onClick={() => handleLinkClick("linkedin")}>
                <FaLinkedin size={24} />
              </SocialIconButton>
              <SocialIconButton aria-label="github" onClick={() => handleLinkClick("github")}>
                <FaGithub size={24} />
              </SocialIconButton>
             
            </Box>

           
          </Grid>

          
        </Grid>
      </Container>
    </StyledFooter>
  );
};

export default Footer;