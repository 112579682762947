import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { motion } from 'framer-motion';
import AdminPage from './AdminPage'; // Assuming you have an AdminPage component
import StudentPage from './StudentPage'; // Assuming you have a StudentPage component
import TutorPage from './TutorPage'; // Assuming you have a TutorPage component
import Button from '@mui/material/Button'; // Material-UI Button component
import CircularProgress from '@mui/material/CircularProgress'; // Material-UI Loading Spinner
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [adminRole, setAdminRole] = useState(false);
  const [studentRole, setStudentRole] = useState(false);
  const [tutorRole, setTutorRole] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [currentPage, setCurrentPage] = useState(null); // State to manage the current page

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = doc(firestore, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser({ ...user, fullName: userSnap.data().fullName, role: userSnap.data().role });
        } else {
          setUser(user);
        }
      } else {
        setUser(null);
      }
      setLoading(false); // Set loading to false once user data is fetched
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      setAdminRole(user.role.includes('admin'));
      setStudentRole(user.role.includes('student'));
      setTutorRole(user.role.includes('tutor'));
    }else{

     setAdminRole(false);
     setStudentRole(false);
     setTutorRole(false);
     
    }
  }, [user]);


  const renderPage = () => {
    if (currentPage === 'admin') {
      return <div className='mt-10 w-full'><AdminPage /></div>;
    } else if (currentPage === 'student') {
      return <div className='mt-10 w-full'><StudentPage/> </div>;
    } else if (currentPage === 'tutor') {
      return <div className='mt-10 w-full'><TutorPage /></div>;
    } else {
      return null;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-20 w-full">
        <CircularProgress /> {/* Material-UI Loading Spinner */}
      </div>
    );
  }

  return (
    <div className={`min-h-screen w-full overscroll-auto`}>
      <div className="container mx-auto p-4 w-full">
       

        {adminRole || studentRole || tutorRole ? ( <div className="flex fixed justify-between items-center mb-22 w-full left-0 top-[80px] z-10 bg-base-300 h-[100px]">
          {currentPage && (<Button onClick={() => setCurrentPage(null)}><ArrowBackIosNewIcon /></Button>)}
          <h1 className="text-3xl font-bold absolute right-10">Dashboard</h1>
          
        </div>) : (
        <div className='w-full text-center justify-center items-center text-2xl font-bold text-red-500'>
          You are not authorized to access this page!
        </div>)}


        {user && currentPage ? (
          renderPage()
        ) : (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-wrap w-full justify-center items-center gap-6 mt-20"
          > 
            {adminRole && (
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="p-6 rounded-lg shadow-lg bg-base-200 min-w-[300px]"
              >
                <h2 className="text-2xl font-semibold mb-4">Admin</h2>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setCurrentPage('admin')}
                >
                  Go to Admin Page
                </Button>
              </motion.div>
            )}

            {studentRole && (
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="p-6 rounded-lg shadow-lg bg-base-200 min-w-[300px]"
              >
                <h2 className="text-2xl font-semibold mb-4">Student</h2>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setCurrentPage('student')}
                >
                  Go to Student Page
                </Button>
              </motion.div>
            )}

            {tutorRole && (
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="p-6 rounded-lg shadow-lg bg-base-200 min-w-[300px]"
              >
                <h2 className="text-2xl font-semibold mb-4">Tutor</h2>
                <Button
                  variant="contained"
                  color="default"
                  fullWidth
                  onClick={() => setCurrentPage('tutor')}
                >
                  Go to Tutor Page
                </Button>
              </motion.div>
            )}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;