import React, { useState, useEffect } from 'react';
import { firestore } from '../../../firebaseConfig';
import { collection, setDoc, deleteDoc, doc, getDocs } from 'firebase/firestore';
import {
  TextField, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel, Toolbar, Typography, Paper, Checkbox, Tooltip, FormControlLabel, Switch,
  MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';

import EditIcon from '@mui/icons-material/Edit';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';

import { toast } from 'react-toastify';

const ManageServices = () => {
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newService, setNewService] = useState({
    alias: '',
    title: '',
    description: '',
    keywords: [],
    startingPrice: '',
    discountPrice: '',
    currency: 'USD',
    link: '',
    image: '',
    technologies: [],
    enabled: true,
    category: '',
    services: [] // Add this line
  });
  const [newCategory, setNewCategory] = useState({
    alias: '',
    title: '',
    description: ''
  });
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [editService, setEditService] = useState(null);
  const [editCategory, setEditCategory] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dense, setDense] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [confirmDelete, setConfirmDelete] = useState({ open: false, id: null });

  useEffect(() => {
    const fetchServices = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'services'));
      const servicesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setServices(servicesList);
    };
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'categories'));
      const categoriesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCategories(categoriesList);
    };
    fetchServices();
    fetchCategories();
  }, []);

  const handleAddService = async () => {
    const serviceToAdd = {
      ...newService,
      lowerTitle: newService.title.toLowerCase()
    };
    await setDoc(doc(firestore, 'services', newService.alias), serviceToAdd);
    setNewService({
      alias: '',
      title: '',
      description: '',
      keywords: [],
      startingPrice: '',
      discountPrice: '',
      currency: 'USD',
      link: '',
      image: '',
      technologies: [],
      enabled: true,
      category: '',
      services: [] // Add this line
    });
    toast.success('Service added successfully');
    setOpenServiceModal(false);
    const querySnapshot = await getDocs(collection(firestore, 'services'));
    const servicesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setServices(servicesList);
  };

  const handleAddCategory = async () => {
    await setDoc(doc(firestore, 'categories', newCategory.alias), newCategory);
    setNewCategory({
      alias: '',
      title: '',
      description: ''
    });
    toast.success('Category added successfully');
    setOpenCategoryModal(false);
    const querySnapshot = await getDocs(collection(firestore, 'categories'));
    const categoriesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCategories(categoriesList);
  };

  const handleDeleteService = async (id) => {
    await deleteDoc(doc(firestore, 'services', id));
    toast.success('Service deleted successfully');
    setServices(services.filter(service => service.id !== id));
    setConfirmDelete({ open: false, id: null });
  };

  const handleDeleteCategory = async (id) => {
    await deleteDoc(doc(firestore, 'categories', id));
    toast.success('Category deleted successfully');
    setCategories(categories.filter(category => category.id !== id));
  };

  const handleUpdateService = async () => {
    const serviceToUpdate = {
      ...editService,
      lowerTitle: editService.title.toLowerCase()
    };
    await setDoc(doc(firestore, 'services', editService.alias), serviceToUpdate);
    setEditService(null);
    toast.success('Service updated successfully');
    const querySnapshot = await getDocs(collection(firestore, 'services'));
    const servicesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setServices(servicesList);
  };

  const handleUpdateCategory = async () => {
    await setDoc(doc(firestore, 'categories', editCategory.alias), editCategory);
    setEditCategory(null);
    toast.success('Category updated successfully');
    const querySnapshot = await getDocs(collection(firestore, 'categories'));
    const categoriesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCategories(categoriesList);
  };

  const handleAddKeyword = () => {
    setNewService({ ...newService, keywords: [...newService.keywords, ''] });
  };

  const handleEditKeyword = (index, value) => {
    const newKeywords = [...editService.keywords];
    newKeywords[index] = value;
    setEditService({ ...editService, keywords: newKeywords });
  };

  const handleDeleteKeyword = (index) => {
    const newKeywords = [...newService.keywords];
    newKeywords.splice(index, 1);
    setNewService({ ...newService, keywords: newKeywords });
  };

  const handleDeleteEditKeyword = (index) => {
    const newKeywords = [...editService.keywords];
    newKeywords.splice(index, 1);
    setEditService({ ...editService, keywords: newKeywords });
  };

  const handleAddTechnology = () => {
    setNewService({ ...newService, technologies: [...newService.technologies, ''] });
  };

  const handleEditTechnology = (index, value) => {
    const newTechnologies = [...editService.technologies];
    newTechnologies[index] = value;
    setEditService({ ...editService, technologies: newTechnologies });
  };

  const handleDeleteTechnology = (index) => {
    const newTechnologies = [...newService.technologies];
    newTechnologies.splice(index, 1);
    setNewService({ ...newService, technologies: newTechnologies });
  };

  const handleDeleteEditTechnology = (index) => {
    const newTechnologies = [...editService.technologies];
    newTechnologies.splice(index, 1);
    setEditService({ ...editService, technologies: newTechnologies });
  };

  const handleAddServiceItem = () => {
    setNewService({ ...newService, services: [...newService.services, ''] });
  };

  const handleEditServiceItem = (index, value) => {
    const newServices = [...editService.services];
    newServices[index] = value;
    setEditService({ ...editService, services: newServices });
  };

  const handleDeleteServiceItem = (index) => {
    const newServices = [...newService.services];
    newServices.splice(index, 1);
    setNewService({ ...newService, services: newServices });
  };

  const handleDeleteEditServiceItem = (index) => {
    const newServices = [...editService.services];
    newServices.splice(index, 1);
    setEditService({ ...editService, services: newServices });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = services.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleSearch = (event) => {
    setSearchKeyword(event.target.value);
  };

  const filteredServices = services.filter(service =>
    service.title.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredServices.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...filteredServices]
        .sort((a, b) => (order === 'asc' ? a[orderBy] > b[orderBy] : a[orderBy] < b[orderBy]) ? 1 : -1)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, filteredServices],
  );

  return (
    <div className="">
      <h1 className="text-2xl font-bold mb-4">Manage Services</h1>
     <div className='flex flex-row justify-center items-center gap-4 mb-5'>
     <Button variant="contained" color="primary" onClick={() => setOpenServiceModal(true)} startIcon={<AddIcon />}>
        Add New Service
      </Button>
      <Button variant="contained" color="secondary" onClick={() => setOpenCategoryModal(true)} startIcon={<AddIcon />}>
        Manage Categories
      </Button>
     </div>
      <div className='bg-white rounded-sm mt-2 px-2  mb-2'>
        <TextField
          label="Search by Title"
          value={searchKeyword}
          onChange={handleSearch}
          fullWidth
          margin="normal"
        />
      </div>
      <Dialog open={openServiceModal} onClose={() => setOpenServiceModal(false)}>
        <DialogTitle>Add New Service</DialogTitle>
        <DialogContent>
          <TextField
            label="Service ID"
            value={newService.alias}
            onChange={(e) => setNewService({ ...newService, alias: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Service Title"
            value={newService.title}
            onChange={(e) => setNewService({ ...newService, title: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={newService.description}
            onChange={(e) => setNewService({ ...newService, description: e.target.value })}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
          <TextField
            label="Starting Price"
            value={newService.startingPrice}
            onChange={(e) => setNewService({ ...newService, startingPrice: e.target.value })}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            label="Discount Price"
            value={newService.discountPrice}
            onChange={(e) => setNewService({ ...newService, discountPrice: e.target.value })}
            fullWidth
            margin="normal"
            type="number"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Currency</InputLabel>
            <Select
              value={newService.currency}
              onChange={(e) => setNewService({ ...newService, currency: e.target.value })}
            >
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="EUR">EUR</MenuItem>
              <MenuItem value="GBP">GBP</MenuItem>
              <MenuItem value="LKR">LKR</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Category</InputLabel>
            <Select
              value={newService.category}
              onChange={(e) => setNewService({ ...newService, category: e.target.value })}
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {newService.keywords.map((keyword, index) => (
            <Box key={index} display="flex" alignItems="center">
              <TextField
                label={`Keyword ${index + 1}`}
                value={keyword}
                onChange={(e) => {
                  const newKeywords = [...newService.keywords];
                  newKeywords[index] = e.target.value;
                  setNewService({ ...newService, keywords: newKeywords });
                }}
                fullWidth
                margin="normal"
              />
              <IconButton onClick={() => handleDeleteKeyword(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={handleAddKeyword}>Add Keyword</Button>
          {newService.technologies.map((tech, index) => (
            <Box key={index} display="flex" alignItems="center">
              <TextField
                label={`Technology ${index + 1}`}
                value={tech}
                onChange={(e) => {
                  const newTechnologies = [...newService.technologies];
                  newTechnologies[index] = e.target.value;
                  setNewService({ ...newService, technologies: newTechnologies });
                }}
                fullWidth
                margin="normal"
              />
              <IconButton onClick={() => handleDeleteTechnology(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={handleAddTechnology}>Add Technology</Button>
          {newService.services.map((service, index) => (
            <Box key={index} display="flex" alignItems="center">
              <TextField
                label={`Service ${index + 1}`}
                value={service}
                onChange={(e) => {
                  const newServices = [...newService.services];
                  newServices[index] = e.target.value;
                  setNewService({ ...newService, services: newServices });
                }}
                fullWidth
                margin="normal"
              />
              <IconButton onClick={() => handleDeleteServiceItem(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={handleAddServiceItem}>Add Service</Button>
          <TextField
            label="Service Link"
            value={newService.link}
            onChange={(e) => setNewService({ ...newService, link: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Image Link"
            value={newService.image}
            onChange={(e) => setNewService({ ...newService, image: e.target.value })}
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            control={<Switch checked={newService.enabled} onChange={(e) => setNewService({ ...newService, enabled: e.target.checked })} />}
            label="Enabled"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenServiceModal(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddService} color="primary">
            Add Service
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openCategoryModal} onClose={() => setOpenCategoryModal(false)}>
        <DialogTitle>Manage Categories</DialogTitle>
        <DialogContent>
          <TextField
            label="Category ID"
            value={newCategory.alias}
            onChange={(e) => setNewCategory({ ...newCategory, alias: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Category Title"
            value={newCategory.title}
            onChange={(e) => setNewCategory({ ...newCategory, title: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={newCategory.description}
            onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
          <Button onClick={handleAddCategory}>Add Category</Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Alias</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((category) => (
                  <TableRow key={category.id}>
                    <TableCell>{category.title}</TableCell>
                    <TableCell>{category.alias}</TableCell>
                    <TableCell>{category.description}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => setEditCategory(category)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteCategory(category.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCategoryModal(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!editService} onClose={() => setEditService(null)}>
        <DialogTitle>Edit Service</DialogTitle>
        <DialogContent>
          {editService && (
            <>
              <TextField
                label="Service ID"
                value={editService.alias}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Service Title"
                value={editService.title}
                onChange={(e) => setEditService({ ...editService, title: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                value={editService.description}
                onChange={(e) => setEditService({ ...editService, description: e.target.value })}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
              <TextField
                label="Starting Price"
                value={editService.startingPrice}
                onChange={(e) => setEditService({ ...editService, startingPrice: e.target.value })}
                fullWidth
                margin="normal"
                type="number"
              />
              <TextField
                label="Discount Price"
                value={editService.discountPrice}
                onChange={(e) => setEditService({ ...editService, discountPrice: e.target.value })}
                fullWidth
                margin="normal"
                type="number"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Currency</InputLabel>
                <Select
                  value={editService.currency}
                  onChange={(e) => setEditService({ ...editService, currency: e.target.value })}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                  <MenuItem value="LKR">LKR</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Category</InputLabel>
                <Select
                  value={editService.category}
                  onChange={(e) => setEditService({ ...editService, category: e.target.value })}
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {editService.keywords.map((keyword, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <TextField
                    label={`Keyword ${index + 1}`}
                    value={keyword}
                    onChange={(e) => handleEditKeyword(index, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <IconButton onClick={() => handleDeleteEditKeyword(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button onClick={() => handleEditKeyword(editService.keywords.length, '')}>Add Keyword</Button>
              {editService.technologies.map((tech, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <TextField
                    label={`Technology ${index + 1}`}
                    value={tech}
                    onChange={(e) => handleEditTechnology(index, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <IconButton onClick={() => handleDeleteEditTechnology(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button onClick={() => handleEditTechnology(editService.technologies.length, '')}>Add Technology</Button>
              {editService.services.map((service, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <TextField
                    label={`Service ${index + 1}`}
                    value={service}
                    onChange={(e) => handleEditServiceItem(index, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <IconButton onClick={() => handleDeleteEditServiceItem(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button onClick={() => handleEditServiceItem(editService.services.length, '')}>Add Service</Button>
              <TextField
                label="Service Link"
                value={editService.link}
                onChange={(e) => setEditService({ ...editService, link: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Image Link"
                value={editService.image}
                onChange={(e) => setEditService({ ...editService, image: e.target.value })}
                fullWidth
                margin="normal"
              />
              <FormControlLabel
                control={<Switch checked={editService.enabled} onChange={(e) => setEditService({ ...editService, enabled: e.target.checked })} />}
                label="Enabled"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditService(null)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateService} color="primary">
            Update Service
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!editCategory} onClose={() => setEditCategory(null)}>
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          {editCategory && (
            <>
              <TextField
                label="Category ID"
                value={editCategory.alias}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Category Title"
                value={editCategory.title}
                onChange={(e) => setEditCategory({ ...editCategory, title: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                value={editCategory.description}
                onChange={(e) => setEditCategory({ ...editCategory, description: e.target.value })}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditCategory(null)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateCategory} color="primary">
            Update Category
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDelete.open} onClose={() => setConfirmDelete({ open: false, id: null })}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this service?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete({ open: false, id: null })} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleDeleteService(confirmDelete.id)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Toolbar
            sx={[
              {
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
              },
              selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
              },
            ]}
          >
            {selected.length > 0 ? (
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Services
              </Typography>
            )}
            {selected.length > 0 ? (
              <Tooltip title="Delete">
                <IconButton onClick={() => setConfirmDelete({ open: true, id: selected[0] })}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Filter list">
                <IconButton>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
             
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selected.length > 0 && selected.length < services.length}
                      checked={services.length > 0 && selected.length === services.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all services',
                      }}
                    />
                  </TableCell>
                  <TableCell
                    key="title"
                    align="left"
                    padding="normal"
                    sortDirection={orderBy === 'title' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'title'}
                      direction={orderBy === 'title' ? order : 'asc'}
                      onClick={(event) => handleRequestSort(event, 'title')}
                    >
                      Title
                      {orderBy === 'title' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="left">Starting Price</TableCell>
                  <TableCell align="left">Discount Price</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows.map((service, index) => {
                  const isItemSelected = selected.includes(service.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, service.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={service.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {service.title}
                      </TableCell>
                      <TableCell align="left">{service.category}</TableCell>
                      <TableCell align="left">{service.startingPrice}</TableCell>
                      <TableCell align="left">{service.discountPrice}</TableCell>
                      <TableCell align="left">
                        <IconButton onClick={() => setEditService(service)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => setConfirmDelete({ open: true, id: service.id })}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredServices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </div>
  );
};

export default ManageServices;
