import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { auth, googleProvider, microsoftProvider, firestore } from '../firebaseConfig';
import { signInWithPopup, signInWithEmailAndPassword, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signOut } from "firebase/auth";
import { collection, doc, getDoc, setDoc, query, where, getDocs } from "firebase/firestore";
import { FaGoogle, FaMicrosoft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import { AppBar, Tabs, Tab, Typography, Box, TextField, Button, useTheme } from '@mui/material';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';

const Login = () => {
  const redirect = window.location.search ? new URLSearchParams(window.location.search).get('redirect')?.replace(window.location.origin, '') : null;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailLink, setEmailLink] = useState('');
  const [user, setUser] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();
  const linkRef = useRef(null);

  useEffect(() => {
    // Automatically trigger a click on the link when the component mounts
 
    if (linkRef.current) {

      
      linkRef.current.click();
    }
  }, [user]);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = doc(firestore, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser({ ...user, fullName: userSnap.data().fullName || user.email });
        } else {
          setUser({ ...user, fullName: user.email });
        }
      } else {
        setUser(null);
      }
    });

    if(redirect && user){

      document.getElementById('redirect-link').click();
     
    //   window.location.href = redirect;
   }
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    handleEmailLinkSignIn();
  }, []);

  const getCustomErrorMessage = (error) => {

    switch (error.code) {
      case 'auth/user-not-found':
        return 'No user found with this email.';
      case 'auth/wrong-password':
        return 'The password is invalid or the user does not have a password.';
      case 'auth/email-already-in-use':
        return 'The provided email is already in use by an existing user.';
      case 'auth/invalid-email':
        return 'Invalid email address.';
      case 'auth/operation-not-allowed':
        return 'Operation not allowed. Please contact support.';
      case 'auth/weak-password':
        return 'Password is too weak. Please choose a stronger password.';
      case 'auth/claims-too-large':
        return 'The claims payload exceeds the maximum allowed size of 1000 bytes.';
      case 'auth/id-token-expired':
        return 'The provided Firebase ID token is expired.';
      case 'auth/id-token-revoked':
        return 'The Firebase ID token has been revoked.';
      case 'auth/insufficient-permission':
        return 'Insufficient permission to access the requested resource.';
      case 'auth/invalid-argument':
        return 'An invalid argument was provided.';
      case 'auth/invalid-claims':
        return 'The custom claim attributes are invalid.';
      case 'auth/invalid-creation-time':
        return 'The creation time must be a valid UTC date string.';
      case 'auth/invalid-disabled-field':
        return 'The provided value for the disabled user property is invalid.';
      case 'auth/invalid-display-name':
        return 'The provided value for the displayName user property is invalid.';
      case 'auth/invalid-email-verified':
        return 'The provided value for the emailVerified user property is invalid.';
      case 'auth/invalid-hash-algorithm':
        return 'The hash algorithm must match one of the supported algorithms.';
      case 'auth/invalid-hash-block-size':
        return 'The hash block size must be a valid number.';
      case 'auth/invalid-hash-derived-key-length':
        return 'The hash derived key length must be a valid number.';
      case 'auth/invalid-hash-key':
        return 'The hash key must be a valid byte buffer.';
      case 'auth/invalid-hash-memory-cost':
        return 'The hash memory cost must be a valid number.';
      case 'auth/invalid-hash-parallelization':
        return 'The hash parallelization must be a valid number.';
      case 'auth/invalid-hash-rounds':
        return 'The hash rounds must be a valid number.';
      case 'auth/invalid-hash-salt-separator':
        return 'The hashing algorithm salt separator field must be a valid byte buffer.';
      case 'auth/invalid-id-token':
        return 'The provided ID token is not a valid Firebase ID token.';
      case 'auth/invalid-last-sign-in-time':
        return 'The last sign-in time must be a valid UTC date string.';
      case 'auth/invalid-page-token':
        return 'The provided next page token in listUsers() is invalid.';
      case 'auth/invalid-password':
        return 'The provided value for the password user property is invalid.';
      case 'auth/invalid-password-hash':
        return 'The password hash must be a valid byte buffer.';
      case 'auth/invalid-password-salt':
        return 'The password salt must be a valid byte buffer.';
      case 'auth/invalid-photo-url':
        return 'The provided value for the photoURL user property is invalid.';
      case 'auth/invalid-provider-data':
        return 'The providerData must be a valid array of UserInfo objects.';
      case 'auth/invalid-oauth-responsetype':
        return 'Only exactly one OAuth responseType should be set to true.';
      case 'auth/invalid-session-cookie-duration':
        return 'The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.';
      case 'auth/invalid-uid':
        return 'The provided uid must be a non-empty string with at most 128 characters.';
      case 'auth/invalid-user-import':
        return 'The user record to import is invalid.';
      case 'auth/maximum-user-count-exceeded':
        return 'The maximum allowed number of users to import has been exceeded.';
      case 'auth/missing-hash-algorithm':
        return 'Importing users with password hashes requires that the hashing algorithm and its parameters be provided.';
      case 'auth/missing-uid':
        return 'A uid identifier is required for the current operation.';
      case 'auth/missing-oauth-client-secret':
        return 'The OAuth configuration client secret is required to enable OIDC code flow.';
      case 'auth/phone-number-already-exists':
        return 'The provided phoneNumber is already in use by an existing user.';
      case 'auth/project-not-found':
        return 'No Firebase project was found for the credential used to initialize the Admin SDKs.';
      case 'auth/reserved-claims':
        return 'One or more custom user claims provided are reserved.';
      case 'auth/session-cookie-expired':
        return 'The provided Firebase session cookie is expired.';
      case 'auth/session-cookie-revoked':
        return 'The Firebase session cookie has been revoked.';
      case 'auth/uid-already-exists':
        return 'The provided uid is already in use by an existing user.';
      case 'auth/admin-restricted-operation':
        return 'This operation is restricted to administrators only.';
      case 'auth/app-not-authorized':
        return 'This app is not authorized to use Firebase Authentication with the provided API key.';
      case 'auth/app-not-installed':
        return 'The requested mobile application is not installed on this device.';
      case 'auth/captcha-check-failed':
        return 'The reCAPTCHA response token is either invalid, expired, or already used.';
      case 'auth/code-expired':
        return 'The SMS code has expired. Please re-send the verification code.';
      case 'auth/cordova-not-ready':
        return 'Cordova framework is not ready.';
      case 'auth/cors-unsupported':
        return 'This browser is not supported.';
      case 'auth/credential-already-in-use':
        return 'This credential is already associated with a different user account.';
      case 'auth/custom-token-mismatch':
        return 'The custom token corresponds to a different audience.';
      case 'auth/requires-recent-login':
        return 'This operation requires recent authentication. Log in again before retrying.';
      case 'auth/dependent-sdk-initialized-before-auth':
        return 'Another Firebase SDK was initialized before Auth. Initialize Auth first.';
      case 'auth/dynamic-link-not-activated':
        return 'Please activate Dynamic Links in the Firebase Console.';
      case 'auth/email-change-needs-verification':
        return 'Multi-factor users must always have a verified email.';
      case 'auth/email-already-in-use':
        return 'The email address is already in use by another account.';
      case 'auth/emulator-config-failed':
        return 'Auth instance has already been used to make a network call. Configure the emulator sooner.';
      case 'auth/expired-action-code':
        return 'The action code has expired.';
      case 'auth/cancelled-popup-request':
        return 'This operation has been cancelled due to another conflicting popup.';
      case 'auth/internal-error':
        return 'An internal error has occurred.';
      case 'auth/invalid-app-credential':
        return 'The phone verification request contains an invalid application verifier.';
      case 'auth/invalid-app-id':
        return 'The mobile app identifier is not registered for the current project.';
      case 'auth/invalid-user-token':
        return 'This user\'s credential isn\'t valid for this project.';
      case 'auth/invalid-auth-event':
        return 'An internal error has occurred.';
      case 'auth/invalid-verification-code':
        return 'The SMS verification code is invalid. Please resend the verification code.';
      case 'auth/invalid-continue-uri':
        return 'The continue URL provided is invalid.';
      case 'auth/invalid-cordova-configuration':
        return 'Required Cordova plugins are not installed.';
      case 'auth/invalid-custom-token':
        return 'The custom token format is incorrect.';
      case 'auth/invalid-dynamic-link-domain':
        return 'The provided dynamic link domain is not configured or authorized.';
      case 'auth/invalid-email':
        return 'The email address is badly formatted.';
      case 'auth/invalid-emulator-scheme':
        return 'Emulator URL must start with a valid scheme (http:// or https://).';
      case 'auth/invalid-api-key':
        return 'Your API key is invalid.';
      case 'auth/invalid-cert-hash':
        return 'The SHA-1 certificate hash provided is invalid.';
      case 'auth/invalid-credential':
        return 'Invalid Login Credentials';
      case 'auth/invalid-message-payload':
        return 'The email template contains invalid characters.';
      case 'auth/invalid-multi-factor-session':
        return 'The request does not contain a valid proof of first factor successful sign-in.';
      case 'auth/invalid-oauth-provider':
        return 'EmailAuthProvider is not supported for this operation.';
      case 'auth/invalid-oauth-client-id':
        return 'The OAuth client ID provided is invalid.';
      case 'auth/unauthorized-domain':
        return 'This domain is not authorized for OAuth operations.';
      case 'auth/invalid-action-code':
        return 'The action code is invalid.';
      case 'auth/invalid-persistence-type':
        return 'The specified persistence type is invalid.';
      case 'auth/invalid-phone-number':
        return 'The format of the phone number provided is incorrect.';
      case 'auth/invalid-provider-id':
        return 'The specified provider ID is invalid.';
      case 'auth/invalid-recipient-email':
        return 'The email corresponding to this action failed to send.';
      case 'auth/invalid-sender':
        return 'The email template contains an invalid sender email or name.';
      case 'auth/invalid-verification-id':
        return 'The verification ID used to create the phone auth credential is invalid.';
      case 'auth/invalid-tenant-id':
        return 'The Auth instance\'s tenant ID is invalid.';
      case 'auth/missing-android-pkg-name':
        return 'An Android Package Name must be provided if the Android App is required to be installed.';
      case 'auth/auth-domain-config-required':
        return 'Be sure to include authDomain when calling firebase.initializeApp().';
      case 'auth/missing-app-credential':
        return 'The phone verification request is missing an application verifier assertion.';
      case 'auth/missing-verification-code':
        return 'The phone auth credential was created with an empty SMS verification code.';
      case 'auth/missing-continue-uri':
        return 'A continue URL must be provided in the request.';
      case 'auth/missing-iframe-start':
        return 'An internal error has occurred.';
      case 'auth/missing-ios-bundle-id':
        return 'An iOS Bundle ID must be provided if an App Store ID is provided.';
      case 'auth/missing-or-invalid-nonce':
        return 'The request does not contain a valid nonce.';
      case 'auth/missing-multi-factor-info':
        return 'No second factor identifier is provided.';
      case 'auth/missing-multi-factor-session':
        return 'The request is missing proof of first factor successful sign-in.';
      case 'auth/missing-phone-number':
        return 'To send verification codes, provide a phone number for the recipient.';
      case 'auth/missing-verification-id':
        return 'The phone auth credential was created with an empty verification ID.';
      case 'auth/app-deleted':
        return 'This instance of FirebaseApp has been deleted.';
      case 'auth/multi-factor-info-not-found':
        return 'The user does not have a second factor matching the identifier provided.';
      case 'auth/multi-factor-auth-required':
        return 'Proof of ownership of a second factor is required to complete sign-in.';
      case 'auth/account-exists-with-different-credential':
        return 'An account already exists with the same email address but different sign-in credentials.';
      case 'auth/network-request-failed':
        return 'A network error has occurred.';
      case 'auth/no-auth-event':
        return 'An internal error has occurred.';
      case 'auth/no-such-provider':
        return 'User was not linked to an account with the given provider.';
      case 'auth/null-user':
        return 'A null user object was provided for an operation requiring a non-null user object.';
      case 'auth/operation-not-allowed':
        return 'The given sign-in provider is disabled for this Firebase project.';
      case 'auth/operation-not-supported-in-this-environment':
        return 'This operation is not supported in the current environment.';
      case 'auth/popup-blocked':
        return 'Unable to establish a connection with the popup. It may have been blocked by the browser.';
      case 'auth/popup-closed-by-user':
        return 'The popup has been closed by the user before finalizing the operation.';
      case 'auth/provider-already-linked':
        return 'User can only be linked to one identity for the given provider.';
      case 'auth/quota-exceeded':
        return 'The project\'s quota for this operation has been exceeded.';
      case 'auth/redirect-cancelled-by-user':
        return 'The redirect operation has been cancelled by the user.';
      case 'auth/redirect-operation-pending':
        return 'A redirect sign-in operation is already pending.';
      case 'auth/rejected-credential':
        return 'The request contains malformed or mismatching credentials.';
      case 'auth/second-factor-already-in-use':
        return 'The second factor is already enrolled on this account.';
      case 'auth/maximum-second-factor-count-exceeded':
        return 'The maximum allowed number of second factors on a user has been exceeded.';
      case 'auth/tenant-id-mismatch':
        return 'The provided tenant ID does not match the Auth instance\'s tenant ID.';
      case 'auth/timeout':
        return 'The operation has timed out.';
      case 'auth/user-token-expired':
        return 'The user\'s credential is no longer valid. The user must sign in again.';
      case 'auth/too-many-requests':
        return 'We have blocked all requests from this device due to unusual activity. Try again later.';
      case 'auth/unauthorized-continue-uri':
        return 'The domain of the continue URL is not whitelisted.';
      case 'auth/unsupported-first-factor':
        return 'Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.';
      case 'auth/unsupported-persistence-type':
        return 'The current environment does not support the specified persistence type.';
      case 'auth/unsupported-tenant-operation':
        return 'This operation is not supported in a multi-tenant context.';
      case 'auth/unverified-email':
        return 'The operation requires a verified email.';
      case 'auth/user-cancelled':
        return 'The user did not grant your application the permissions it requested.';
      case 'auth/user-not-found':
        return 'There is no user record corresponding to this identifier.';
      case 'auth/user-disabled':
        return 'The user account has been disabled by an administrator.';
      case 'auth/user-mismatch':
        return 'The supplied credentials do not correspond to the previously signed in user.';
      case 'auth/weak-password':
        return 'The password must be 6 characters long or more.';
      case 'auth/web-storage-unsupported':
        return 'This browser is not supported or 3rd party cookies and data may be disabled.';
      case 'auth/already-initialized':
        return 'initializeAuth() has already been called with different options.';
      default:
        return 'Unknown Error Occurred! [ ' + error.code + ' ]';
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      await saveUserToFirestore(user);
      toast.success('Logged in with Google successfully!');
    } catch (error) {
      toast.error(getCustomErrorMessage(error));
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      const result = await signInWithPopup(auth, microsoftProvider);
      const user = result.user;
      await saveUserToFirestore(user);
      toast.success('Logged in with Microsoft successfully!');
    } catch (error) {
      toast.error(getCustomErrorMessage(error));
    }
  };

  const handleEmailPasswordLogin = async () => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const user = result.user;
      await saveUserToFirestore(user);
      toast.success('Logged in with email and password successfully!');
    } catch (error) {
      toast.error(getCustomErrorMessage(error));
    }
  };

  const handleEmailLinkLogin = async () => {
    try {
      const usersRef = collection(firestore, 'users');
      const userQuery = query(usersRef, where('email', '==', emailLink));
      const querySnapshot = await getDocs(userQuery);
      if (!querySnapshot.empty) {
        await sendSignInLinkToEmail(auth, emailLink, {
          url: window.location.href,
          handleCodeInApp: true,
        });
        window.localStorage.setItem('emailForSignIn', emailLink);
        toast.success('Email link sent!');
      } else {
        toast.error('Email not found in users collection.');
      }
    } catch (error) {
      toast.error(getCustomErrorMessage(error));
    }
  };

  const handleEmailLinkSignIn = async () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      try {
        const result = await signInWithEmailLink(auth, email, window.location.href);
        const user = result.user;
        window.localStorage.removeItem('emailForSignIn');
        await saveUserToFirestore(user);
        setUser(user); // Ensure user session is set
        toast.success('Logged in with email link successfully!');
      } catch (error) {
        toast.error(getCustomErrorMessage(error));
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
     
      toast.success('Logged out successfully!');
    } catch (error) {
      toast.error(getCustomErrorMessage(error));
    }
  };

  const saveUserToFirestore = async (user) => {
    try {
      const userRef = doc(firestore, 'users', user.uid);
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) {
        await setDoc(userRef, {
          uid: user.uid,
          email: user.email,
          name: user.displayName || '',
          profilePicture: user.photoURL || '',
          role: ['student'],
        });
      } else {
        setUser({ ...user, fullName: userSnap.data().name });
      }
    } catch (error) {
      toast.error(getCustomErrorMessage(error));
    }
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center justify-center min-h-full"
    >
      <div className="w-full flex flex-col gap-3 items-center justify-center max-w-md bg-base-100 h-[500px] rounded-2xl shadow-xl">




        

        {user ? (
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
            className="flex flex-col items-center gap-3 w-full"
          >
            <div className="mb-4 text-base-content">Logged in as {user.fullName || user.email}</div>
          {
            user && redirect ? (  <div>
              <Link to={redirect}  ref={linkRef}
              
            
            className="w-full btn btn-warning text-gray-900 hover:bg-warning-focus shadow-lg"
          >
            Click Here Proceed to Redirect
          </Link>
        
            </div>) : 
            
            
            ( <div>
               <Button
              variant="contained"
              color="secondary"
              onClick={handleLogout}
              className="w-full"
            >
              Logout
            </Button>
            </div>)
          }
          </motion.div>
        ) : (
          <Box sx={{ width: '100%' }} className="h-full rounded-2xl">
            <AppBar position="static">
              <Tabs
                value={tabIndex}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="scrollable"
                color="inherit"
                scrollButtons="auto"
                aria-label="full width tabs example"
                className=' flex flex-col items-center justify-center text-center text-white'
              >
                <Tab label="Legacy Login" {...a11yProps(0)} />
                <Tab label="Quick Login" {...a11yProps(1)} />
                <Tab label="Link Login" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={tabIndex} index={0} dir={theme.direction}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className='flex border-2 border-blue-500 flex-col gap-3  bg-blue-50 px-4 h-[300px] rounded-md w-full'
              >
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mb-4"
                />
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  variant="outlined"
                  margin="normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mb-4"
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleEmailPasswordLogin}
                   className="top-5 mb-4 h-14"
                >
                  Login with Email & Password
                </Button>
              </motion.div>
            </TabPanel>
            <TabPanel value={tabIndex} index={1} dir={theme.direction}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className='flex border-2 border-blue-500 flex-col gap-3  bg-blue-50 px-4 py-20 h-[300px] rounded-md w-full'
              >
                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  startIcon={<FaGoogle />}
                  onClick={handleGoogleLogin}
                  className="mb-4 h-14"
                >
                  Login with Google
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<FaMicrosoft />}
                  onClick={handleMicrosoftLogin}
                  className="mb-4 h-14"
                >
                  Login with Microsoft
                </Button>
              </motion.div>
            </TabPanel>
            <TabPanel value={tabIndex} index={2} dir={theme.direction}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className='flex border-2 border-blue-500 flex-col gap-3  bg-blue-50 px-4 h-[300px] rounded-md w-full'
              >
                <TextField
                  fullWidth
                  label="Email for Link"
                  variant="outlined"
                  margin="normal"
                  color='warning'
                  value={emailLink}
                  onChange={(e) => setEmailLink(e.target.value)}
                  className="mb-4"
                />
                <Button
                  variant="contained"
                  color="warning"
                  fullWidth
                    className="top-5 mb-4 h-14"
                  onClick={handleEmailLinkLogin}
                >
                  Send Login Link
                </Button>
              </motion.div>
            </TabPanel>
            <Box textAlign="center" className="mt-6">
              <Link to="/register" className="text-blue-600 hover:underline">
                Don't have an account? Register {'>' + redirect}
              </Link>
            </Box>
          </Box>
        )}
      </div>
      
    </motion.div>
    
  );
};

export default Login;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography> {/* Changed from <Typography> to <Typography component="div"> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}