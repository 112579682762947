// src/pages/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import HomeIcon from '@mui/icons-material/Home';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
`;

const AnimatedIcon = styled(MoodBadIcon)(({ theme }) => ({
  animation: `${bounce} 2s infinite`,
  fontSize: '4rem',
  color: theme.palette.error.main,
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `-webkit-linear-gradient(45deg, ${theme.palette.error.main} 30%, ${theme.palette.warning.main} 90%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 'bold',
}));

const NotFound = () => {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          py: 8,
        }}
      >
        <Box sx={{ position: 'relative', mb: 4 }}>
          <AnimatedIcon sx={{ position: 'absolute', left: -80, top: -20 }} />
          <AnimatedIcon sx={{ position: 'absolute', right: -80, top: -20 }} />
          
          <GradientText variant="h1" sx={{ fontSize: { xs: '4rem', md: '6rem' }, mb: 2 }}>
            404
          </GradientText>
        </Box>

        <Typography variant="h4" component="h2" sx={{ mb: 3, fontWeight: 'medium' }}>
          Oops! Page Not Found
        </Typography>

        <Typography variant="body1" sx={{ maxWidth: 600, mb: 4, fontSize: '1.1rem' }}>
          The page you're looking for might have been removed, had its name changed,
          or is temporarily unavailable.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/"
          startIcon={<HomeIcon />}
          sx={{
            px: 6,
            py: 1.5,
            fontSize: '1.1rem',
            borderRadius: 2,
            textTransform: 'none',
            boxShadow: 4,
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: 6,
            },
            transition: 'all 0.3s ease',
          }}
        >
          Return to Home
        </Button>

        <Typography variant="body2" sx={{ mt: 4, color: 'text.base' }}>
          Still lost? Contact us at <a className=' font-semibold underline hover:no-underline' href='mailto:officialkasun@officialkasun.com'>officialkasun@officialkasun.com</a>
        </Typography>
      </Box>
    </Container>
  );
};

export default NotFound;