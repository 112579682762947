import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ManageUsers from './admin/ManageUsers';
import ManageCourses from './admin/ManageCourses';
import { styled } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import InventoryIcon from '@mui/icons-material/Inventory';
import MenuIcon from '@mui/icons-material/Menu';
import ManageProjects from './admin/ManageProjects';
import ManageServices from './admin/ManageServices';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const actions = [
  { icon: <MenuIcon />, name: 'Menu', code: 'menu' },

];

export default function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(null);
  const [direction, setDirection] = React.useState('down');
  const [hidden, setHidden] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleMenuClick = (text) => () => {
    switch (text) {
      case 'menu':
        toggleDrawer(true)();
        break;
   
      default:
        console.log('OFFICIALKASUN.COM - ADMIN ERROR: Invalid page code');
    }
  };

  const handleClick = (text) => () => {
    switch (text) {
      case 'manage-users':
        setCurrentPage('manage-users');
        break;
      case 'manage-courses':
        setCurrentPage('manage-courses');
        break;

        case 'manage-projects':
          setCurrentPage('manage-projects');
          break;

          case 'manage-services':
            setCurrentPage('manage-services');
            break;
      default:
        console.log('OFFICIALKASUN.COM - ADMIN ERROR: Invalid page code');
    }
  };

  const renderPage = () => {
    if (currentPage === 'manage-users') {
      return <ManageUsers />;
    } else if (currentPage === 'manage-courses') {
      return <ManageCourses />;
    } else if (currentPage === 'manage-projects') {
      return <ManageProjects />;
    }
    else if (currentPage === 'manage-services') {
      return <ManageServices />;
    } 
    else {
      return null;
    }
  };

  const DrawerList = (
    <div className="bg-base-300 text-base-content h-screen">
      
      <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
        <List>
          {[
            { text: 'Manage Users', icon: <PeopleIcon className='text-base-content' />, code: 'manage-users' },
            { text: 'Manage Courses', icon: <LibraryBooksIcon className='text-base-content' />, code: 'manage-courses' },
            { text: 'Manage Projects', icon: <InventoryIcon className='text-base-content' />, code: 'manage-projects' },
            { text: 'Manage Services', icon: <MiscellaneousServicesIcon className='text-base-content' />, code: 'manage-services' },
          ].map((item, index) => (
            <ListItem key={item.text} disablePadding className='hover:scale-105 duration-500 transition-all ease-in-out hover:font-semibold'>
              <ListItemButton onClick={handleClick(item.code)}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Box>
    </div>
  );

  return (

    <div>
      <div className='z-20 w-full fixed flex justify-start items-center top-[230px] left-20 '> 
      <Box sx={{ position: 'absolute', mt: 3, height: 320 }}>
        <StyledSpeedDial
          ariaLabel="Admin Buddy"
          hidden={hidden}
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          direction={direction}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              
              onClick={handleMenuClick(action.code)}
            />
          ))}
        </StyledSpeedDial>
      </Box>
      </div>
    <div className="flex justify-center items-center">
     
      {currentPage === null ? (
        <div className='flex flex-col justify-center items-center gap-5'>
          <h1 className="text-3xl font-bold">Admin Dashboard</h1>
          <p className="text-lg">Welcome to the Admin Dashboard!</p>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            onClick={handleMenuClick('menu')}
            tabIndex={-1}
            startIcon={<MenuIcon />}
          > Admin Menu
          </Button>
        </div>
      ) : (
        <div className='w-full'>{renderPage()}</div>
      )}

      </div>

      <div>
          
          <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
          </Drawer>
        </div>
     
    </div>
  );
}
