import React, { useState, useEffect } from 'react';
import { firestore } from '../../../firebaseConfig';
import { collection, setDoc, deleteDoc, doc, getDocs } from 'firebase/firestore';
import {
  TextField, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel, Toolbar, Typography, Paper, Checkbox, Tooltip, FormControlLabel, Switch,
  MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from '@mui/icons-material/Edit';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';

import { toast } from 'react-toastify';
import { countries } from 'countries-list';

const ManageProjects = () => {
  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState({
    alias: '',
    description: '',
    estimatedCost: '',
    currency: 'USD',
    image: '',
    link: '',
    category: '',
    technologies: [],
    title: '',
    year: new Date().getFullYear(),
    country: '',
    status: 'Completed',
    duration: '',
    clientType: 'Corporate'
  });
  const [open, setOpen] = useState(false);
  const [editProject, setEditProject] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dense, setDense] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [confirmDelete, setConfirmDelete] = useState({ open: false, id: null });

  const countryList = Object.entries(countries).map(([code, country]) => ({
    code,
    name: country.name
  }));

  const categories = [
    'Web App Development',
    'Software Development',
    'Android App Development',
    'iOS App Development',
    'Cloud Solutions',
    'DevOps Implementation',
    'Enterprise Software',
    'E-commerce Solutions',
    'CRM Systems',
    'AI/ML Solutions',
    'Blockchain Development',
    'IoT Solutions'
  ];

  useEffect(() => {
    const fetchProjects = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'projects'));
      const projectsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjects(projectsList);
    };
    fetchProjects();
  }, []);

  const handleAddProject = async () => {
    const projectToAdd = {
      ...newProject,
      lowerTitle: newProject.title.toLowerCase()
    };
    await setDoc(doc(firestore, 'projects', newProject.alias), projectToAdd);
    setNewProject({
      alias: '',
      description: '',
      estimatedCost: '',
      currency: 'USD',
      image: '',
      link: '',
      category: '',
      technologies: [],
      title: '',
      year: new Date().getFullYear(),
      country: '',
      status: 'Completed',
      duration: '',
      clientType: 'Corporate'
    });
    toast.success('Project added successfully');
    setOpen(false);
    const querySnapshot = await getDocs(collection(firestore, 'projects'));
    const projectsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setProjects(projectsList);
  };

  const handleDeleteProject = async (id) => {
    await deleteDoc(doc(firestore, 'projects', id));
    toast.success('Project deleted successfully');
    setProjects(projects.filter(project => project.id !== id));
    setConfirmDelete({ open: false, id: null });
  };

  const handleUpdateProject = async () => {
    const projectToUpdate = {
      ...editProject,
      lowerTitle: editProject.title.toLowerCase()
    };
    await setDoc(doc(firestore, 'projects', editProject.alias), projectToUpdate);
    setEditProject(null);
    toast.success('Project updated successfully');
    const querySnapshot = await getDocs(collection(firestore, 'projects'));
    const projectsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setProjects(projectsList);
  };

  const handleAddTechnology = () => {
    setNewProject({ ...newProject, technologies: [...newProject.technologies, ''] });
  };

  const handleEditTechnology = (index, value) => {
    const newTechnologies = [...editProject.technologies];
    newTechnologies[index] = value;
    setEditProject({ ...editProject, technologies: newTechnologies });
  };

  const handleDeleteTechnology = (index) => {
    const newTechnologies = [...newProject.technologies];
    newTechnologies.splice(index, 1);
    setNewProject({ ...newProject, technologies: newTechnologies });
  };

  const handleDeleteEditTechnology = (index) => {
    const newTechnologies = [...editProject.technologies];
    newTechnologies.splice(index, 1);
    setEditProject({ ...editProject, technologies: newTechnologies });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = projects.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleSearch = (event) => {
    setSearchKeyword(event.target.value);
  };

  const filteredProjects = projects.filter(project =>
    project.title.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredProjects.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...filteredProjects]
        .sort((a, b) => (order === 'asc' ? a[orderBy] > b[orderBy] : a[orderBy] < b[orderBy]) ? 1 : -1)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, filteredProjects],
  );

  return (
    <div className="">
      <h1 className="text-2xl font-bold mb-4">Manage Projects</h1>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)} startIcon={<AddIcon />}>
        Add New Project
      </Button>
     <div className='bg-white rounded-sm mt-2 px-2  mb-2'>
     <TextField
        label="Search by Title"
        value={searchKeyword}
        onChange={handleSearch}
        fullWidth
        
        margin="normal"
      />
     </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add New Project</DialogTitle>
        <DialogContent>
          <TextField
            label="Project ID"
            value={newProject.alias}
            onChange={(e) => setNewProject({ ...newProject, alias: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Project Title"
            value={newProject.title}
            onChange={(e) => setNewProject({ ...newProject, title: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={newProject.description}
            onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
          <TextField
            label="Estimated Cost"
            value={newProject.estimatedCost}
            onChange={(e) => setNewProject({ ...newProject, estimatedCost: e.target.value })}
            fullWidth
            margin="normal"
            type="number"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Currency</InputLabel>
            <Select
              value={newProject.currency}
              onChange={(e) => setNewProject({ ...newProject, currency: e.target.value })}
            >
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="EUR">EUR</MenuItem>
              <MenuItem value="GBP">GBP</MenuItem>
              <MenuItem value="LKR">LKR</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Category</InputLabel>
            <Select
              value={newProject.category}
              onChange={(e) => setNewProject({ ...newProject, category: e.target.value })}
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Country</InputLabel>
            <Select
              value={newProject.country}
              onChange={(e) => setNewProject({ ...newProject, country: e.target.value })}
            >
              {countryList.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Year"
            value={newProject.year}
            onChange={(e) => setNewProject({ ...newProject, year: e.target.value })}
            fullWidth
            margin="normal"
            type="number"
          />
          {newProject.technologies.map((tech, index) => (
            <Box key={index} display="flex" alignItems="center">
              <TextField
                label={`Technology ${index + 1}`}
                value={tech}
                onChange={(e) => {
                  const newTechnologies = [...newProject.technologies];
                  newTechnologies[index] = e.target.value;
                  setNewProject({ ...newProject, technologies: newTechnologies });
                }}
                fullWidth
                margin="normal"
              />
              <IconButton onClick={() => handleDeleteTechnology(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={handleAddTechnology}>Add Technology</Button>
          <TextField
            label="Project Link"
            value={newProject.link}
            onChange={(e) => setNewProject({ ...newProject, link: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Image Link"
            value={newProject.image}
            onChange={(e) => setNewProject({ ...newProject, image: e.target.value })}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddProject} color="primary">
            Add Project
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!editProject} onClose={() => setEditProject(null)}>
        <DialogTitle>Edit Project</DialogTitle>
        <DialogContent>
          {editProject && (
            <>
              <TextField
                label="Project ID"
                value={editProject.alias}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Project Title"
                value={editProject.title}
                onChange={(e) => setEditProject({ ...editProject, title: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                value={editProject.description}
                onChange={(e) => setEditProject({ ...editProject, description: e.target.value })}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
              <TextField
                label="Estimated Cost"
                value={editProject.estimatedCost}
                onChange={(e) => setEditProject({ ...editProject, estimatedCost: e.target.value })}
                fullWidth
                margin="normal"
                type="number"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Currency</InputLabel>
                <Select
                  value={editProject.currency}
                  onChange={(e) => setEditProject({ ...editProject, currency: e.target.value })}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                  <MenuItem value="LKR">LKR</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Category</InputLabel>
                <Select
                  value={editProject.category}
                  onChange={(e) => setEditProject({ ...editProject, category: e.target.value })}
                >
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Country</InputLabel>
                <Select
                  value={editProject.country}
                  onChange={(e) => setEditProject({ ...editProject, country: e.target.value })}
                >
                  {countryList.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Year"
                value={editProject.year}
                onChange={(e) => setEditProject({ ...editProject, year: e.target.value })}
                fullWidth
                margin="normal"
                type="number"
              />
              {editProject.technologies.map((tech, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <TextField
                    label={`Technology ${index + 1}`}
                    value={tech}
                    onChange={(e) => handleEditTechnology(index, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <IconButton onClick={() => handleDeleteEditTechnology(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button onClick={() => handleEditTechnology(editProject.technologies.length, '')}>Add Technology</Button>
              <TextField
                label="Project Link"
                value={editProject.link}
                onChange={(e) => setEditProject({ ...editProject, link: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Image Link"
                value={editProject.image}
                onChange={(e) => setEditProject({ ...editProject, image: e.target.value })}
                fullWidth
                margin="normal"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditProject(null)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateProject} color="primary">
            Update Project
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDelete.open} onClose={() => setConfirmDelete({ open: false, id: null })}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this project?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete({ open: false, id: null })} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleDeleteProject(confirmDelete.id)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Toolbar
            sx={[
              {
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
              },
              selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
              },
            ]}
          >
            {selected.length > 0 ? (
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Projects
              </Typography>
            )}
            {selected.length > 0 ? (
              <Tooltip title="Delete">
                <IconButton onClick={() => setConfirmDelete({ open: true, id: selected[0] })}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Filter list">
                <IconButton>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
             
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selected.length > 0 && selected.length < projects.length}
                      checked={projects.length > 0 && selected.length === projects.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all projects',
                      }}
                    />
                  </TableCell>
                  <TableCell
                    key="title"
                    align="left"
                    padding="normal"
                    sortDirection={orderBy === 'title' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'title'}
                      direction={orderBy === 'title' ? order : 'asc'}
                      onClick={(event) => handleRequestSort(event, 'title')}
                    >
                      Title
                      {orderBy === 'title' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="left">Estimated Cost</TableCell>
                  <TableCell align="left">Year</TableCell>
                  <TableCell align="left">Country</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows.map((project, index) => {
                  const isItemSelected = selected.includes(project.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, project.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={project.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {project.title}
                      </TableCell>
                      <TableCell align="left">{project.category}</TableCell>
                      <TableCell align="left">{project.estimatedCost}</TableCell>
                      <TableCell align="left">{project.year}</TableCell>
                      <TableCell align="left">{project.country}</TableCell>
                      <TableCell align="left">
                        <IconButton onClick={() => setEditProject(project)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => setConfirmDelete({ open: true, id: project.id })}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredProjects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </div>
  );
};

export default ManageProjects;
