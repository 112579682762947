import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import './App.css';

import Navbar from './components/navbar';
import Home from './pages/Home';
import About from './pages/About';
import NotFound from './pages/NotFound';
import Footer from './components/footer';
import Refund from './pages/tc/Refund';
import Privacy from './pages/tc/Privacy';
import Terms from './pages/tc/Terms';
import All from './pages/courses/All';
import CourseSearch from './pages/courses/CourseSearch';
import Manage from './pages/courses/Manage';
import Login from './pages/Login';
import Register from './pages/Register';
import GoogleDrivePicker from './components/GoogleDrivePicker';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import Dashboard from './pages/dashboard/Dashboard';
import Projects from './pages/projects/Projects';
import Services from './pages/services/Services';
import PayTrack from './pages/payments/PayTrack';


// ScrollTop component handles showing/hiding the button and scroll action
function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Fade  in={trigger}>
      <Box 
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

// BackToTop component wraps the main content and includes the ScrollTop button
export default function BackToTop(props) {



    const [theme, setTheme] = useState(
      localStorage.getItem("theme") || "system"
    );

    React.useEffect(() => {
      localStorage.getItem("theme") || "system"
    
    }, [theme]);

  return (
    <Router>
      <ScrollToTop />
      <React.Fragment>
        <CssBaseline />
        
        
        {/* AppBar with Navbar */}
        <AppBar className='select-none'>
        
            <Navbar />
            <ToastContainer theme={theme} position="top-right"
      autoClose={5000}
      closeOnClick
      draggable
      pauseOnHover

      />
        </AppBar>

        <Navbar /> {/* Navbar remains fixed */}
        
        
     
        <div className='select-none' style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div className='mb-20' style={{ flex: 1, overflowY: 'auto' }}>
       
          <Toolbar id="back-to-top-anchor" />
   
          <Container>
        
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/payments/tracker" element={<PayTrack />} />
              <Route path="/about" element={<About />} />
              <Route path="/courses" element={<All />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/services" element={<Services  />} />
              <Route path="/courses/:course" element={<CourseSearch />} />
              <Route path="/tc/refund" element={<Refund />} />
              <Route path="/tc/privacy" element={<Privacy />} />
              <Route path="/tc/terms" element={<Terms />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
              </div>

              <div style={{ position: 'relative', bottom: 0, width: '100%' }}>
          <Footer />
              </div>
            </div>
          {/* ScrollTop Button */}
        <ScrollTop {...props} >
          <Fab size="small" aria-label="scroll back to top"  >
            <KeyboardArrowUpIcon  />
          </Fab>
        </ScrollTop>
      </React.Fragment>

     
    </Router>
  );
}

function ScrollToTop() {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}
