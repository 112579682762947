import React from 'react';

const ManageUsers = () => {
  return (
    <div>
      <h1>Manage Users</h1>
      <p>Under Development</p>
    </div>
  );
};

export default ManageUsers;
