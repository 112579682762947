import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig';
import { FaExclamationTriangle, FaSearch } from 'react-icons/fa';
import { Modal } from '@mui/material';
import { Email, Phone, WhatsApp } from '@mui/icons-material';

const Services = ({ mode, count }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [fixedSearch, setFixedSearch] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({ content: [], title: '' });

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async (term = '') => {
    setLoading(true);
    setFixedSearch(term);
    try {
      let q = collection(firestore, 'services');
      if (term) {
        const termLower = term.toLowerCase();
        q = query(q, where('keywords', 'array-contains', termLower));
      }
      const querySnapshot = await getDocs(q);
      const servicesList = querySnapshot.docs
        .map(doc => doc.data())
        .filter(service => service.enabled === true);
      setServices(servicesList);
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setLoading(false);
    }
  };

  const searchService = () => {
    fetchServices(searchTerm);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchService();
    }
  };

  const handleOpenModal = (content, title) => {
    const formattedContent = content.map(item => {
      if (typeof item === 'object') {
        return item;
      }
      return {
        label: item,
        value: '#',
        icon: null
      };
    });
    setModalContent({ content: formattedContent, title });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const displayedServices = count ? services.slice(0, count) : services;

  const searchBar = () => (
    <div className="w-full mb-8">
      <div className="max-w-md mx-auto relative">
        <input
          type="text"
          placeholder="Search for Services..."
          className="w-full px-4 py-3 pl-12 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary input input-bordered"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
          <FaSearch className="w-5 h-5 text-gray-400" />
        </div>
        <button
          onClick={searchService}
          className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
        >
          <FaSearch className="w-5 h-5 text-gray-400" />
        </button>
      </div>
      <div className='mt-2 text-red-600 italic w-full justify-center text-center items-center'>
        Explore our premium services.
      </div>
    </div>
  );

  if (loading) {
    return (
      <>
        {mode !== 'nosearch' && searchBar()}
        <div className="flex items-center justify-center">
          <span className="loading loading-spinner loading-lg text-red-500"></span>
        </div>
      </>
    );
  }

  if (!loading && services.length === 0) {
    return (
      <>
        {mode !== 'nosearch' && searchBar()}
        <div className="flex flex-col items-center justify-center gap-4 p-4">
          <FaExclamationTriangle className="text-6xl text-error animate-pulse" />
          <h2 className="text-3xl font-bold text-center">No Services Found</h2>
          <p className="text-center text-lg text-gray-600">
            It seems there are no available services {fixedSearch && `under the "${fixedSearch}"`} at the moment.
          </p>
        </div>
      </>
    );
  }

  return (
    <div className="w-full flex flex-wrap gap-8 p-4 justify-center ">
      {mode !== 'nosearch' && searchBar()}
      {displayedServices.map((service, index) => (
        <div 
          key={index}
          className="card bg-base-100 rounded-lg shadow-lg overflow-hidden p-6 transition-all duration-300 hover:scale-105 w-full sm:w-[calc(50%-1rem)] lg:w-[calc(33.333%-1rem)]"
        >
          <div className='flex flex-col gap-3'>
            <div className="flex items-center justify-center">
              {service.image ? (
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-32 h-32 p-2 object-cover border-4 border-primary rounded-full"
                />
              ) : (
                <div className="skeleton w-32 h-32 rounded-full bg-base-300"></div>
              )}
            </div>
            <div className="text-center">
              <h2 className="text-xl font-semibold text-base-content">{service.title}</h2>
              <p className="text-gray-500 mt-2 line-clamp-3 h-20 overflow-auto">{service.description}</p>
            </div>
            <div className="text-center flex justify-center flex-col gap-2 items-center">
              <h2 className="text-xl text-orange-400 font-semibold">Starting Price</h2>
              <p className='text-red-600 italic font-semibold'>from</p>
              <p className="text-white rounded-lg p-3 text-xl hover:bg-orange-800 font-semibold mt-2 line-clamp-3 bg-orange-700 w-max"> {service.currency} {service.startingPrice}</p>
            </div>
            {service.discountPrice && (
              <div className="text-center">
                <h2 className="text-xl text-blue-400 font-semibold">Discount Price</h2>
                <p className="text-orange-700 font-semibold mt-2 line-clamp-3">{service.currency} {service.discountPrice}</p>
              </div>
            )}
            <div className="text-center mt-10">
              <h2 className="text-xl font-semibold bg-gradient-to-r from-blue-300 to-blue-600 text-transparent bg-clip-text border-b-2 border-blue-800 mb-3">Technologies</h2>
              <p className="text-orange-700 font-semibold mt-2 line-clamp-3">
                {service.technologies.slice(0, 3).join(', ')}
                {service.technologies.length > 3 && (
                  <span 
                    className="text-orange-500 cursor-pointer"
                    onClick={() => handleOpenModal(service.technologies, 'Technologies')}
                  >
                    {' '}...view more
                  </span>
                )}
              </p>
            </div>
            <div className="text-center mt-10">
              <h2 className="text-xl font-semibold bg-gradient-to-r from-red-500 to-red-800 text-transparent bg-clip-text border-b-2 border-red-800 mb-3">Services</h2>
              <p className="text-orange-700 font-semibold mt-2 line-clamp-3">
                {service.services.slice(0, 2).join(', ')}
                {service.services.length > 2 && (
                  <span 
                    className="text-orange-500 cursor-pointer"
                    onClick={() => handleOpenModal(service.services, 'Services')}
                  >
                    {' '}...view more
                  </span>
                )}
              </p>
            </div>
            <div className="flex justify-center">
              {service.link ? (
                <a
                  href={service.link} target='_blank' rel='noreferrer'
                  className="btn btn-primary mt-4 px-8 hover:bg-primary-focus transition-colors"
                >
                  View Service
                </a>
              ) : (
                <button
                  onClick={() => handleOpenModal([
                    { type: 'email', value: 'mailto:officialkasun@officialkasun.com', label: 'officialkasun@officialkasun.com', icon: <Email /> },
                    { type: 'phone', value: 'tel:+94764783434', label: '0764783434', icon: <Phone /> },
                    { type: 'whatsapp', value: 'https://wa.me/94764783434', label: 'WhatsApp', icon: <WhatsApp /> }
                  ], 'Contact Us')}
                  className="btn btn-primary mt-4 px-8 hover:bg-primary-focus transition-colors"
                >
                  Contact Us
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className='justify-center items-center w-full flex'
      >
        <div className="modal-box">
          <h2 id="modal-title" className="text-2xl font-bold mb-4">{modalContent.title}</h2>
          <ul id="modal-description" className="text-lg list-disc list-inside space-y-2">
            {modalContent.content.map((item, index) => (
              <li key={index} className="hover:text-red-500 hover:font-semibold hover:scale-105 hover:cursor-pointer transition-all duration-300">
                {item.icon} {item.value !== "#" ? <a href={item.value ? item.value : '#'} target={item.value !== "#" ? "_blank" : undefined} rel="noreferrer">{item.label}</a> :  item.label}
              </li>
            ))}
          </ul>
          <div className="modal-action">
            <button className="btn" onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Services;