import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaYoutube } from "react-icons/fa";

const YoutubeLatest = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_KEY = "AIzaSyAkN7OkTLhiVGjmeGMfBr1KkcTUwv8kPJ0"; // Replace with your YouTube API key
  const CHANNEL_ID = "UCfVL8l-mQpsaViwoNtVlihw"; // OfficialKasun channel ID
  const MAX_COUNT = 3;

  const offlineVideos = [
    {
      id: { videoId: "V-NzuK1B6l8?si=WJX1HAGcPdxSwWPi" },
      snippet: {
        title: "Python Lesson 01 - Introduction and Installation ( Sinhala ) - පයිතන් පළමු පාඩම",
        description: `මෙම කොටසින් මම සාකච්ඡා කරන්නේ, Python කියන ක්‍රමලේඛන භාෂාව ගැන ඔබ දැනගත යුතු අත්‍යාවශ්‍ය කරුණු සහ එය ඔබගේ පරිගණකය ට ස්ථාපනය (install) කරගන්නේ කෙලෙස ද, යන්න යි...  | උසස් පෙළ "තොරතුරු හා සන්නිවේදන තාක්ෂණය" සහ Python ඉගෙනගැනීම ට කැමැත්තක් ඇති සියළු දෙනාට මෙය වැදගත් වේ.
`,
        thumbnails: {
          high: {
            url: "https://i.ytimg.com/vi/V-NzuK1B6l8/hqdefault.jpg?sqp=-oaymwEjCNACELwBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLDmbAUBfwsZjyBdqoR0RhqKTRoojg",
          },
        },
      },
    },
    {
      id: { videoId: "YEzGQ_aVPjQ?si=vTpRJ9EtXOfHqvrq" },
      snippet: {
        title: `Python Lesson 02 - Run "Hello World" Program ( Sinhala ) - පයිතන් දෙවන පාඩම`,
        description: `මෙම කොටසින් මම සාකච්ඡා කරන්නේ, Python කියන ක්‍රමලේඛන භාෂාව භාවිතා කරමින් නිවැරදි ආකාරයට "Hello World" වැඩසටහනක් ලියන ආකාරය ගැන ය. දන්නවා කියලා හිතාගෙන හිටියට දන්නේ නැති තැන් ගොඩක් තියෙනවා... ඒ හින්දා අනිවාර්යයෙන් බලන්න...`,
        thumbnails: {
          high: {
            url: "https://i.ytimg.com/vi/YEzGQ_aVPjQ/hqdefault.jpg?sqp=-oaymwEjCNACELwBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLB_uKHLXpxV1C4rjXJpTa5JpWKE3A",
          },
        },
      },
    },
    {
      id: { videoId: "u0uunTlMDrc?si=haXwrMSxu2i7BMGf" },
      snippet: {
        title: "Python Lesson 03 (Part I) - Python IDE as a Calculator ( Sinhala ) - Mathematics - පයිතන්",
        description: `මෙම කොටසින් මම සාකච්ඡා කරන්නේ, Python කියන ක්‍රමලේඛන භාෂාව සමඟ පැමිණෙන IDE (Integrated Development Environment) එක සරළ ගණකයන්ත්‍රයක් විදිහ ට භාවිතා කරන ආකාරය සහ එය Programming වල දී වැදගත් වන්නේ කෙසේද යන්න පැහැදිලි කිරීම යි.  | උසස් පෙළ "තොරතුරු හා සන්නිවේදන තාක්ෂණය" සහ Python ඉගෙනගැනීම ට කැමැත්තක් ඇති සියළු දෙනාට මෙය වැදගත් වේ.`,
        thumbnails: {
          high: {
            url: "https://i.ytimg.com/vi/u0uunTlMDrc/hqdefault.jpg?sqp=-oaymwEjCNACELwBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLC1LGAoSo2rMDaSelC75ymNUg2gfw",
          },
        },
      },
    },
  ];


  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`https://www.googleapis.com/youtube/v3/search`, {
          params: {
            part: "snippet",
            channelId: CHANNEL_ID,
            maxResults: MAX_COUNT,
            order: "date",
            key: API_KEY,
          },
        });
        setVideos(response.data.items);
        setLoading(false);
      } catch (err) {

        const options = {
            method: 'GET',
            url: 'https://youtube-v3-alternative.p.rapidapi.com/channel',
            params: {
              id: CHANNEL_ID
            },
            headers: {
              'x-rapidapi-key': '03ca7885aamsh16ad2617c9f6479p185e46jsn5e1f0adaf2dd',
              'x-rapidapi-host': 'youtube-v3-alternative.p.rapidapi.com'
            }
          };

       
          
          
          try {
              const response = await axios.request(options);
              //console.log(response.data.data);
              let counter = 0;
              const offlineVideosRapid = response.data.data.slice(0, 3).map(video => ({
                id: {
                    videoId: video.videoId,
                },
                snippet: {
                    title: video.title,
                    description: video.description,
                    viewCount: video.viewCount,
                    publishedText: video.publishedText,
                    lengthText: video.lengthText,
                    thumbnails: {
                        high: {
                            url: video.thumbnail[3].url,
                        },
                    },
                },
            }));
            

            
              setVideos(offlineVideosRapid);
              setLoading(false);
          } catch (error) {
            setError("Python Programming on ");
            setVideos(offlineVideos)
            setLoading(false);
          }


      
        
        
      }
    };

    fetchVideos();
  }, []);

  if (loading)
    return (
      <div>
        <div className="flex w-52 flex-col gap-4">
          <div className="skeleton h-32 w-full"></div>
          <div className="skeleton h-4 w-28"></div>
          <div className="skeleton h-4 w-full"></div>
          <div className="skeleton h-4 w-full"></div>
        </div>
      </div>
    );


  return (
     <div className='block text-center w-full justify-center gap-10 text-2xl font-semibold '>
      <h2 className="font-semibold text-2xl  mb-4 flex flex-wrap justify-center gap-3 items-center text-red-600 border-b-red-600 border-b-2">
        {error ? error : "Latest Videos on"} <a href="https://www.youtube.com/officialkasun" target="_blank" rel="noopener noreferrer"><FaYoutube /> </a>
      </h2>
      <div className="flex flex-col items-center justify-center w-full md:grid sm:grid-cols-2 lg:grid-cols-3 gap-6   overflow-hidden">
        {videos.map((video) => (
          <div
            key={video.id.videoId}
            className="max-w-sm flex flex-col justify-between h-full mb-10 rounded-xl hover:translate-y-[10px] transition-all duration-700 overflow-hidden bg-base-100 hover:bg-base-200 shadow-lg w-80 md:w-full"
          >
            <img
              className=" w-80 md:w-full"
              src={video.snippet.thumbnails.high.url}
              alt={video.snippet.title}
            />
            <div className="px-6 py-4 flex-grow w-80 md:w-full text-wrap">
              <h3 className="font-bold text-xl mb-2 md:w-full">{video.snippet.title}</h3>
              <p className="text-gray-700 text-base   md:w-full">
                {video.snippet.description.slice(0, 100)}...
              </p>
            </div>
            <div className="px-6 py-4 mt-auto flex flex-row justify-center text-center items-end">
              <a
                href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                target="_blank"
                rel="noopener noreferrer"
                className=" w-full flex items-center justify-center text-center btn bg-red-600 hover:bg-red-500 text-white"
              >
                Watch Video <FaYoutube />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YoutubeLatest;
