import React from 'react';

const Terms = () => {
  return (
    <div className="text-gray-800 p-6 sm:p-10 lg:p-20">
      <div className="max-w-4xl mx-auto bg-base-100 text-base-content shadow-lg rounded-xl overflow-hidden hover:scale-105 transition-all duration-700">
        <div className="p-6 sm:p-10 text-justify">
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-blue-600 mb-6">
            Terms and Conditions
          </h1>
          <p className="mb-8 text-red-500">
            <strong>Last Updated: January 30, 2025</strong>
          </p>
          <p className="text-base sm:text-lg mb-4">
            Welcome to <span className="font-bold">officialkasun.com</span>. These Terms and Conditions govern your access to and use of our educational platform, including enrollment in online and offline courses, live sessions, and digital materials. By using this platform, you agree to comply with these terms. Please review them carefully before purchasing or accessing any courses.
          </p>

          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Use of the Platform</h2>
          <ul className="list-disc list-inside mb-4 flex flex-col gap-2 ml-10">
            <li>Users must be at least 10 years old to create an account, with parental guidance required for users under 16. Users aged 16 and above may create accounts independently.</li>
            <li>You are responsible for maintaining the confidentiality of your account credentials and for all activities under your account.</li>
            <li>You agree to provide accurate and current information during the registration and payment processes.</li>
            <li>Unauthorized use of our platform, including illegal or abusive activities, is strictly prohibited.</li>
          </ul>

          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Course Enrollment and Access</h2>
          <ul className="list-disc list-inside mb-4 flex flex-col gap-2 ml-10">
            <li>Course enrollment is subject to availability and may be limited to certain timeframes.</li>
            <li>Once enrolled, you may access course materials for the specified duration and in accordance with the terms outlined for each course.</li>
            <li>Unauthorized distribution or sharing of course content is strictly prohibited.</li>
          </ul>

          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Payments and Refunds</h2>
          <ul className="list-disc list-inside mb-4 flex flex-col gap-2 ml-10">
            <li>Payments may be made through our secure internet payment gateway, bank transactions, or by visiting our physical location.</li>
            <li>All fees are displayed in the applicable currency and may be subject to taxes or additional charges.</li>
            <li>Refund requests are subject to our refund policy, which can be found on our website.</li>
            <li>Chargebacks or payment disputes may result in account suspension or termination.</li>
          </ul>

          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Course Completion and Certification</h2>
          <p className="mb-4">
            Upon successful completion of a course, participants may receive an e-certificate that can be verified on <a href="https://cert.prolearners.lk" className="text-blue-600" target="_blank" rel="noopener noreferrer">cert.prolearners.lk</a>, our partner Learning Management System (LMS). Certificates can be linked to platforms such as LinkedIn, GitHub, or any other social media.
          </p>

          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Intellectual Property</h2>
          <ul className="list-disc list-inside mb-4 flex flex-col gap-2 ml-10">
            <li>All educational content, including videos, documents, and materials, is protected by intellectual property laws and is owned by us or our licensors.</li>
            <li>Reproduction, modification, distribution, or sharing of content without prior written consent is strictly prohibited.</li>
          </ul>

          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Limitation of Liability</h2>
          <p className="mb-4">We are not liable for any direct or indirect damages arising from the use of our platform or educational content, including but not limited to any loss of data, income, or profit.</p>

          <h2 className="text-xl font-semibold text-blue-500 mt-8 mb-4">Amendments and Termination</h2>
          <p className="mb-4">We reserve the right to modify, update, or terminate these Terms and Conditions at any time without prior notice. Continued use of the platform after any changes constitutes acceptance of the revised terms.</p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
